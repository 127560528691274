if (
  window.location.href.includes("https://wm-admin.testyourapp.online") ||
  window.location.href.includes("localhost")
) {
  console.log(window.location.href,"local or dev from APIURL.js")
  localStorage.setItem("Platform", "DEV");
} else if (
  window.location.href.includes("https://www.admin-test.woningmarkt.nl/")
) {
  console.log(window.location.href,"UAT from APIURL.js")
  localStorage.setItem("Platform", "UAT");
} else {
  console.log(window.location.href,"PROD from APIURL.js")
  localStorage.setItem("Platform", "PROD");
}
const platform = localStorage.getItem("Platform");

export default {
  backendUrl: process.env[`REACT_APP_${platform}_BACKEND_URL`],
  // backendUrl: "https://api.woningmarkt.nl/api/admin",
};
