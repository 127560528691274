import React from "react";
import MagazineDetailsMain from "../../components/MagazineDetailsMain";

const MagazineDetailsPage = () => {
  return (
    <React.Fragment>
      <MagazineDetailsMain />
    </React.Fragment>
  );
};

export default MagazineDetailsPage;
