import React, { useState } from "react";
import classes from "./brokerheader.module.scss";
import { useNavigate } from "react-router-dom";
import { actionGetBrokerButtonApiCall } from "../../../Redux/Actions";
import { useDispatch } from "react-redux";

const BrokerHeader = () => {

  const [headerSearch, setHeaderSearch] = useState({
    name: "",
    isActive: "",
    menuType: ""
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const filterClick = () => {
    const body = {
      navigate,
      name: headerSearch.name,
      menuType: headerSearch.menuType,
      isActive: headerSearch.isActive,
    };
    console.log("the body inside filterClick is", body);

    dispatch(actionGetBrokerButtonApiCall(body));
  };
  const onClickOfButton = (e) => {
    e.preventDefault();
    filterClick();
  };


  return (

    <div className={classes.HeaderWrap}>
      <div className={classes.ManagmentSection}>
        <div className={classes.FilterSection}>
          <h5>
            <span onClick={() => navigate("/system-management")}>
              System Management
            </span>{" "}
          </h5>
          <form className={classes.managementFild} onSubmit={onClickOfButton}>
            <div className={classes.fildBox}>
              <input
                placeholder="Name"
                onChange={(e) => {
                  setHeaderSearch({
                    ...headerSearch,
                    name: e.target.value,
                  });
                }}
                value={headerSearch.name}
              />
            </div>
            <div className={classes.fildBox}>
              <select
                value={headerSearch.isActive}
                onChange={(e) =>
                  setHeaderSearch({
                    ...headerSearch,
                    isActive: e.target.value,
                  })
                }
              >
                <option value="">Active Status</option>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>

            {/* menuType Select */}
            <div className={classes.fildBox}>
              <select
                value={headerSearch.menuType}
                onChange={(e) =>
                  setHeaderSearch({
                    ...headerSearch,
                    menuType: e.target.value,
                  })
                }
              >
                <option value="">Menu Type</option>
                <option value="Main_menu">Main Menu</option>
                <option value="Top_menu">Top Menu</option>
              </select>
            </div>
            <div className={classes.fildBox} onClick={onClickOfButton}>
              <button>Filter</button>
            </div>
            <div className={classes.fildBox} onClick={() => setHeaderSearch({
              name: "",
              isActive: "",
              menuType: ""
            })}>
              <button>Reset</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BrokerHeader;
