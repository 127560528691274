import React, { useEffect, useRef, useState } from "react";
import classes from "./detailorgpage.module.scss";
import { useTranslation } from "react-i18next";
import EditIcon from "../../../assets/images/EditLogo.svg";
import SearchIcon from "../../../assets/images/SearchIcon.svg";
import StarIcon from "../../../assets/images/starIcon.svg";
import FileUploadIcon from "../../../assets/icons/FileUploadIcon.svg";
import { HiOutlineMailOpen } from "react-icons/hi";
import { IoInformationCircleOutline } from "react-icons/io5";
import { TbMessageCircle2Filled } from "react-icons/tb";
import { IoIosSend } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import moment from "moment";
import { MdHomeWork } from "react-icons/md";
import {
  FaUserPlus,
  FaUserSlash,
  FaDownload,
  FaUpload,
  FaFacebookF,
} from "react-icons/fa";
import { IoDocumentTextSharp, IoLogoInstagram } from "react-icons/io5";
import { GrDocumentVerified } from "react-icons/gr";
import { MdEditDocument } from "react-icons/md";
import { RiGlobalLine } from "react-icons/ri";
import { AiFillLinkedin } from "react-icons/ai";
import CustomDropdown from "../../common/CustomDropdown";
import OffcanvasMain from "../../common/Offcanvas";
import AddressForm from "../../common/AddressForm";
import ContactForm from "../../common/ContactForm";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LogForm from "../../common/LogForm";
import {
  actionAddAgencyBranchesApiCall,
  actionAddAgencyClassificationApiCall,
  actionBanchNestedGetApi,
  actionClassificationNestedGetApi,
  actionContactLinkingToOrgApiCall,
  actionGetContactLogApiCall,
  actionLangListAPICall,
  actionOrganizationGetCitiesApiCall,
  actionSaveToken,
  actionSendPasswordLinkAPICall,
  actionUpdateOrganizationApiCall,
  actionViewTokenApiCall,
} from "../../../Redux/Actions";
import ViewProfile from "../../common/ViewProfile";
import CustomSelect from "../../common/CustomSelect";
import Swal from "sweetalert2";

const DetailOrgPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const singleOrgData = useSelector(
    (state) => state.CommonReducer.singleOrgData
  );

  const subscriptionListdata = useSelector(
    (state) => state.CommonReducer.saveSubscriptionListForOrg
  );
  const [isHovered, setIsHovered] = useState();
  const state = useSelector((state) => state?.CommonReducer);
  const [editDataPass, setEditDataPass] = useState();
  const [contactFormShow, setContactFormShow] = useState({
    data: null,
    status: false,
  });
  const [addressFormShow, setAddressFormShow] = useState({
    data: null,
    status: false,
  });
  const [viewProfile, setViewProfile] = useState(false);
  const [logFormShow, setLogFormShow] = useState({
    data: null,
    status: false,
  });
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    [t("organisation_name")]: singleOrgData?.name,
    [t("email_")]: singleOrgData?.email,
    [t("set_language")]: singleOrgData?.language,
    [t("facebook")]: singleOrgData?.facebook,
    [t("city_p")]: singleOrgData?.city,
    [t("phonenumber")]: singleOrgData?.phone,
    [t("website")]: singleOrgData?.website,
    [t("instagram")]: singleOrgData?.instagram,
    [t("country")]: singleOrgData?.country,
    [t("linkedin")]: singleOrgData?.linkedin,
    [t("org_created_at")]: singleOrgData?.created_at,
    [t("data_provider_id")]: singleOrgData?.agency_import?.import_id,
    [t("data_provider")]: singleOrgData?.agency_import?.import_type,
    [t("membership_status")]: singleOrgData?.member_status,
    [t("banner_budget")]: singleOrgData?.banner_budget
      ? singleOrgData?.banner_budget
      : 0,
  });
  const [importData, setImportData] = useState();
  const [importProfileStatus, setImportProfileStatus] = useState();
  const [contactList, setContactList] = useState(singleOrgData?.agency_contact);
  const [contactSearch, setContactSearch] = useState();
  const [addressSearch, setAddressSearch] = useState();
  const ProfileRef = useRef(null);
  const ImportRef = useRef(null);
  const FinancialRef = useRef(null);
  const [error, setError] = useState({});
  const [isOpen, setIsOpen] = useState({
    isOpen1: false,
    isOpen2: false,
    isOpen3: false,
  });
  let pathname = useLocation().pathname;
  pathname = pathname.substring(pathname.lastIndexOf("/") + 1);
  const loading = useSelector((state) => state.CommonReducer.loader);

  const langdata = useSelector(
    (state) => state.CommonReducer?.SaveLangListData
  );
  const contactLog = useSelector(
    (state) => state.CommonReducer?.contactLogData
  );
  console.log(contactLog, "contactttttttttt")
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const handleKeyPressForNumber = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 8 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  const dataProviderArray = [
    { label: "Realworks", value: "TIARA" },
    { label: "Kolibri", value: "KOLIBRI" },
    { label: "Skarabee", value: "SKARABEE" },
    { label: "Other", value: "OTHER" },
    { label: "None", value: "" },
  ];
  const inputItems = [
    {
      name: t("organisation_name"),
      placeholder: t("lamberink_amsterdam"),
    },
    {
      name: t("email_"),
      placeholder: t("temp_email"),
    },
    {
      name: t("set_language"),
      placeholder: t("english"),
      type: "select",
      options: langdata,
    },
    {
      name: t("facebook"),
      placeholder: "",
      icon: FaFacebookF,
    },
    {
      type: "select",
      name: t("city_p"),
      apiName: "city",
      placeholder: t("city_p"),
      options:
        state?.getCityData &&
        state?.getCityData?.map((p) => {
          return { label: p.name, value: p.name };
        }),
    },
    {
      name: t("phonenumber"),
      placeholder: "",
    },
    {
      name: t("website"),
      placeholder: "",
      icon: RiGlobalLine,
    },
    {
      name: t("instagram"),
      placeholder: "",
      icon: IoLogoInstagram,
    },
    {
      type: "select",
      placeholder: t("country"),
      name: t("country"),
      options:
        state?.getCountryTable &&
        state?.getCountryTable?.map((p) => {
          return { label: p.name, value: p.name };
        }),
      apiName: "country",
    },
    {
      name: t("linkedin"),
      placeholder: "",
      icon: AiFillLinkedin,
    },
  ];
  const [listProperty, setListProperty] = useState(
    singleOrgData?.agency_address
  );

  const iconsList = [
    { icon: IoDocumentTextSharp, description: "Quatation recieved" },
    { icon: MdEditDocument, description: "Invoice recieved" },
    { icon: GrDocumentVerified, description: "Order Confirmation" },
    { icon: FaUpload, description: "Delivery e-mail " },
    { icon: IoIosSend, description: "Send Password Reset" },
    { icon: FaUserPlus, description: "Create User" },
    { icon: FaUserSlash, description: "Create User Not Possible" },
  ];
  const dummyData = [
    { id: 1, name: "New registration", value: "PENDING" },
    { id: 2, name: "Profile check", value: "TO_APPROVE" },
    { id: 3, name: "OK", value: "APPROVED" },
  ];
  const importStatus = [
    { id: 1, name: "No import", value: "" },
    { id: 2, name: "Pdf downloaded", value: "PDF_DOWNLOADED" },
    { id: 3, name: "Pdf uploaded", value: "PDF_UPLOADED" },
    { id: 4, name: "Pdf send", value: "PDF_SEND" },
    { id: 5, name: "Import pending", value: "PENDING" },
    { id: 6, name: "Ok Penthion", value: "TO_APPROVE" },
    { id: 7, name: "Import Oke", value: "APPROVED" },
    { id: 8, name: "Error", value: "ERROR" },
    { id: 9, name: "Deactivated", value: "DEACTIVATED" },
  ];

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [window]);

  function getWindowSize() {
    return window.innerWidth;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (singleOrgData?.agency_import?.profile_status) {
        let data = dummyData.find(
          (item) => item?.value == singleOrgData?.agency_import?.profile_status
        );
        setImportData(data?.name);
      }
      if (singleOrgData?.agency_import?.status) {
        let data = importStatus.find(
          (item) => item?.value == singleOrgData?.agency_import?.status
        );
        setImportProfileStatus(data?.name);
      }
      setFormData({
        [t("organisation_name")]: singleOrgData?.name,
        [t("email_")]: singleOrgData?.email,
        [t("set_language")]: singleOrgData?.language,
        [t("facebook")]: singleOrgData?.facebook,
        [t("city_p")]: singleOrgData?.city,
        [t("phonenumber")]: singleOrgData?.phone,
        [t("website")]: singleOrgData?.website
          ? singleOrgData?.website.trim()
          : "",
        [t("instagram")]: singleOrgData?.instagram,
        [t("country")]: singleOrgData?.country,
        [t("linkedin")]: singleOrgData?.linkedin,
        [t("org_created_at")]: singleOrgData?.created_at,
        [t("data_provider_id")]: singleOrgData?.agency_import?.import_id,
        [t("data_provider")]: singleOrgData?.agency_import?.import_type,
        [t("membership_status")]: singleOrgData?.member_status,
        [t("banner_budget")]: singleOrgData?.banner_budget
          ? singleOrgData?.banner_budget
          : 0,
      });
      setContactList(singleOrgData?.agency_contact);
      setListProperty(singleOrgData?.agency_address);
    }, 0);
    return () => {
      clearTimeout(timer);
    };
  }, [singleOrgData]);

  useEffect(() => {
    if (subscriptionListdata) {
      setFormData({
        ...formData,
        [t("subscription")]: subscriptionListdata.find(
          (item) =>
            item?.payment_status === "paid" &&
            item?.subscription_detail.active === 1
        ),
      });
    }
  }, [subscriptionListdata]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ProfileRef.current && !ProfileRef.current.contains(event.target)) {
        setIsOpen({ ...isOpen, isOpen1: false });
      }
      if (ImportRef.current && !ImportRef.current.contains(event.target)) {
        setIsOpen({ ...isOpen, isOpen2: false });
      }
      if (
        FinancialRef.current &&
        !FinancialRef.current.contains(event.target)
      ) {
        setIsOpen({ ...isOpen, isOpen3: false });
      }
    };

    const body = { navigate };
    dispatch(actionOrganizationGetCitiesApiCall(body));
    dispatch(actionBanchNestedGetApi(body));
    dispatch(actionClassificationNestedGetApi(body));
    dispatch(actionLangListAPICall(body));
    dispatch(actionGetContactLogApiCall({ agency_id: pathname, navigate }));
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (contactSearch) {
      setContactList(
        singleOrgData?.agency_contact.filter((item) =>
          item?.first_name?.toLowerCase().includes(contactSearch.toLowerCase())
        )
      );
    } else {
      setContactList(singleOrgData?.agency_contact);
    }
  }, [contactSearch]);

  useEffect(() => {
    if (addressSearch) {
      setListProperty(
        singleOrgData?.agency_address.filter((item) =>
          item?.zipcode?.toLowerCase().includes(addressSearch.toLowerCase())
        )
      );
    } else {
      setListProperty(singleOrgData?.agency_address);
    }
  }, [addressSearch]);

  const toggleDropdown = (dropdownId) => {
    setIsOpen((prevIsOpen) => ({
      ...prevIsOpen,
      [dropdownId]: !prevIsOpen[dropdownId],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name == t("organisation_name") ? value : value.trim(),
    });
  };

  const handleEditData = (item) => {
    setContactFormShow(true);
    setEditDataPass(item);
  };

  const onClickOfSaveBtn = () => {
    if (validation()) {
      const id = singleOrgData?.id;
      const formdata = new FormData();
      formData[t("organisation_name")] &&
        formdata.append("org_name", formData[t("organisation_name")]);
      formData[t("email_")] && formdata.append("email", formData[t("email_")]);
      formData[t("set_language")] &&
        formdata.append("language", formData[t("set_language")]);
      formData[t("facebook")] &&
        formdata.append("facebook", formData[t("facebook")]);
      formData[t("city_p")] &&
        formdata.append(
          "city",
          formData[t("city_p")]?.value
            ? formData[t("city_p")]?.value
            : formData[t("city_p")]
        );
      formData[t("phonenumber")] &&
        formdata.append("phone", formData[t("phonenumber")]);
      formData[t("website")] &&
        formdata.append("website", formData[t("website")]);
      formData[t("instagram")] &&
        formdata.append("instagram", formData[t("instagram")]);
      formData[t("country")] &&
        formdata.append(
          "country",
          formData[t("country")]?.value
            ? formData[t("country")]?.value
            : formData[t("country")]
        );
      formData[t("linkedin")] &&
        formdata.append("linkedin", formData[t("linkedin")]);

      formData[t("data_provider_id")] &&
        formdata.append("import_id", formData[t("data_provider_id")]);
      formData[t("membership_status")] &&
        formdata.append("member_status", formData[t("membership_status")]);
      formData[t("banner_budget")] &&
        formdata.append("banner_budget", formData[t("banner_budget")]);
      formData[t("data_provider")] &&
        formdata.append(
          formData[t("data_provider")] == "OTHER"
            ? "other_type"
            : "import_type",
          formData[t("data_provider")]
        );
      const body = { formdata, navigate, id, close: () => { }, edit: true };
      dispatch(actionUpdateOrganizationApiCall(body));
    }
  };
  const onClickSendPasswordLink = (user_id) => {
    const data = { userid: user_id };
    const body = { data, navigate, toast: true };
    dispatch(actionSendPasswordLinkAPICall(body));
  };
  const onClickCreateNewUser = (user_email) => {
    const data = { id: user_email };
    const body = {
      data,
      navigate,
      toast: true,
      apiCall: { data: singleOrgData?.id, navigate },
    };
    dispatch(actionContactLinkingToOrgApiCall(body));
  };
  //download pdf onclick
  const handleDownload = (pdfUrl) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", pdfUrl, true);
    xhr.responseType = "blob";

    xhr.onload = function () {
      const blob = xhr.response;
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = "downloaded.pdf";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    };

    xhr.send();
  };

  const subData = (data, name) => {
    const apiCall = (id) => {
      const body = { data: singleOrgData?.id, navigate };
      let obj = {
        agency_id: singleOrgData?.id,
        id: [id],
        viewPayload: body,
        navigate,
      };
      if (name == "branch") {
        dispatch(actionAddAgencyBranchesApiCall(obj));
      } else if (name == "class") {
        dispatch(actionAddAgencyClassificationApiCall(obj));
      }
    };


    return (
      <>
        <input
          type="checkbox"
          id={`${name}` + data.id}
          name={name}
          onChange={() => {
            apiCall(data.id);
          }}
          checked={
            name == "branch" && singleOrgData?.agency_branch_list
              ? singleOrgData?.agency_branch_list.some(
                (item) => item?.id == data.id
              )
              : name == "class" &&
              singleOrgData?.agency_classification_list &&
              singleOrgData?.agency_classification_list.some(
                (item) => item?.id == data.id
              )
          }
        />
        <label
          className={classes.radioCustomLabel}
          htmlFor={`${name}` + data.id}
        >
          {data.name}
        </label>
        {data.subBranches && (
          <ul>{data?.subBranches?.map((sub) => subData(sub, "branch"))}</ul>
        )}
        {data?.subClassifications && (
          <ul>
            {data?.subClassifications?.map((sub) => subData(sub, "class"))}
          </ul>
        )}
      </>
    );
  };
  const validation = () => {
    let valid = true;
    const fieldsToValidate = [
      t("linkedin"),
      t("instagram"),
      t("website"),
      t("facebook"),
    ];
    const newErrors = {};

    fieldsToValidate.forEach((field) => {
      if (formData[field]) {
        const isValidLink = new RegExp(
          /^(https?|ftp):\/\/[^\s$.?#-][^\s]*([\/?][^\s]*)?$/
        ).test(formData[field]);
        newErrors[field] = isValidLink ? "" : "please enter a valid link";
        if (!isValidLink) {
          valid = false;
        }
      }
    });

    if (
      formData[t("email_")] &&
      !new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ).test(formData[t("email_")])
    ) {
      newErrors[t("email_")] = t("please_enter_valid_email_id");
      valid = false;
    }

    setError(newErrors);
    return valid;
  };
  const handleLogin = (item) => {
    console.log(item, "oajsoda")
    const data = item
    const body = { data };
    dispatch(actionViewTokenApiCall(body))
  }
  console.log("contactLog", contactLog)

  return (
    <React.Fragment>
      <div className={classes.detailPage}>
        <div className={classes.gridWrapper}>
          <div className={classes.girdContainer}>
            {inputItems.map((item) => {
              return (
                <div key={item?.name} className={classes.labelContainer}>
                  <label>
                    {item?.name.includes("city") ||
                      item?.name.includes("country")
                      ? item?.placeholder
                      : item?.name}
                  </label>
                  {item?.type === "select" ? (
                    !item?.apiName ? (
                      <select
                        name={item?.name}
                        value={formData[item?.name] || ""}
                        onChange={handleInputChange}
                      >
                        <option value="" disabled selected>
                          {item?.name == t("set_language")
                            ? "Langage"
                            : "Country"}
                        </option>
                        {item?.options.map((option) => (
                          <option
                            key={option.id}
                            value={
                              item?.name == t("set_language")
                                ? option.id
                                : option
                            }
                          >
                            {item?.name == t("set_language")
                              ? option.language_label
                              : option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <CustomSelect
                        defaultInputValue={
                          formData[item?.placeholder] ? true : false
                        }
                        value={{
                          label: formData[item?.placeholder]?.value
                            ? formData[item?.placeholder]?.value
                            : formData[item?.placeholder],
                          value: formData[item?.placeholder]?.value
                            ? formData[item?.placeholder]?.value
                            : formData[item?.placeholder],
                        }}
                        setValue={true}
                        item={item}
                        apiName={item?.apiName}
                        isInputChange={true}
                        headerSearch={formData}
                        setHeaderSearch={setFormData}
                      />
                    )
                  ) : (
                    <div className={classes.inputContainer}>
                      <input
                        placeholder={item?.placeholder}
                        name={item?.name}
                        value={formData[item?.name] || ""}
                        onChange={handleInputChange}
                        maxLength={item?.name == t("phonenumber") && "10"}
                        minLength={item?.name == t("phonenumber") && "8"}
                        onKeyDown={
                          item?.name == t("phonenumber")
                            ? handleKeyPressForNumber
                            : () => { }
                        }
                        onBlur={validation}
                      />
                      {item?.icon && (
                        <div
                          className={classes.iconContainer}
                          onClick={() => {
                            if (formData[item?.name] && !error[item?.name]) {
                              window.open(formData[item?.name], "_blank");
                            }
                          }}
                        >
                          <item.icon />
                        </div>
                      )}
                    </div>
                  )}
                  <p className={classes.errorText}>
                    {error[item?.name] && error[item?.name]}
                  </p>
                </div>
              );
            })}
          </div>
          <button onClick={onClickOfSaveBtn}>Save</button>
        </div>
        <div className={classes.container}>
          <div className={classes.address}>
            <p>{t("addresses")}</p>
            <div className={classes.addressContainer}>
              <div className={classes.tableContainer}>
                <div className={classes.SearchBar}>
                  <img src={SearchIcon} alt="" />
                  <input
                    type="search"
                    placeholder="Search in Addresses"
                    onChange={(e) => setAddressSearch(e.target.value)}
                  />
                </div>
                <div className={classes.TableListngLayOut}>
                  <table>
                    <tr>
                      <th></th>
                      <th>{t("TYPE OF ADDRESS")}</th>
                      <th>{t("HOUSE NO")}</th>
                      <th>{t("POSTCODE")}</th>
                      <th>{t("CITY")}</th>
                      <th>{t("E-MAIL")}</th>
                      <th>{t("#")}</th>
                      <th></th>
                    </tr>
                    {listProperty?.length > 0 ? (
                      listProperty?.map((item) => (
                        <tr
                          className={
                            item?.hide_property == 1
                              ? classes.InvisibleCard
                              : null
                          }
                        >
                          <td>
                            {" "}
                            {item?.set_as_main_address && (
                              <img src={StarIcon} alt="StarIcon" />
                            )}
                          </td>
                          <td>{item?.address_type}</td>
                          <td>{item?.house_no}</td>
                          <td>{item?.zipcode}</td>
                          <td>{item?.city}</td>
                          <td>{item?.email}</td>
                          <td>{item?.phone}</td>
                          <td
                            onClick={() =>
                              setAddressFormShow({
                                data: item,
                                status: true,
                              })
                            }
                          >
                            <div
                              className={classes.ListingIcons}
                              onClick={() => handleEditData(item)}
                            >
                              <img src={EditIcon} alt="" />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className={classes.NoData}>
                        <td className={classes.LoaderWrap} colSpan={6}>
                          <h2>{t("no_data_found")}</h2>
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
              <button
                onClick={() =>
                  setAddressFormShow({
                    data: null,
                    status: true,
                  })
                }
              >
                add addresses
              </button>
            </div>
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.address}>
            <div className={classes.contact}>
              <p>{t("Contacts")}</p>
              <div className={classes.iconsList}>
                {iconsList.map((item) => {
                  return (
                    <div>
                      <item.icon />- {item?.description}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classes.addressContainer}>
              <div className={classes.tableContainer}>
                <div className={classes.SearchBar}>
                  <img src={SearchIcon} alt="" />
                  <input
                    type="search"
                    placeholder="Search in Contacts"
                    onChange={(e) => setContactSearch(e.target.value)}
                  />
                </div>
                <div
                  className={`${classes.TableListngLayOut} ${classes.contactTable}`}
                >
                  <table>
                    <tr>
                      <th></th>
                      <th>{t("NAME")}</th>
                      <th>{t("LAST NAME")}</th>
                      <th>{t("E-MAIL")}</th>
                      <th>{t("PHONE")}</th>
                      <th>{t("JOB TITLE")}</th>
                      <th></th>
                      <th>
                        <div className={classes.ListingIcons}>
                          <IoDocumentTextSharp />
                        </div>
                      </th>
                      <th>
                        <div className={classes.ListingIcons}>
                          <MdEditDocument />
                        </div>
                      </th>
                      <th>
                        <div className={classes.ListingIcons}>
                          <GrDocumentVerified />
                        </div>
                      </th>
                      <th>
                        <div className={classes.ListingIcons}>
                          <img src={FileUploadIcon} alt="" />
                        </div>
                      </th>
                      <th>
                        <div className={classes.ListingIcons}>
                          <MdHomeWork />
                        </div>
                      </th>
                      <th>{t("ACCOUNT")}</th>
                      <th></th>
                    </tr>
                    {console.log(contactList,"contact1997")}
                    {contactList?.length > 0 ? (
                      contactList?.map((item) => (
                        console.log(item, "item123"),
                        <tr
                          className={
                            item?.hide_property == 1
                              ? classes.InvisibleCard
                              : null
                          }
                        >
                          <td
                            className={`${classes.starIcon} ${classes.iconStyle}`}
                          >
                            {item?.set_as_main_contact && (
                              <img src={StarIcon} alt="" />
                            )}
                          </td>
                          <td className={classes.setContent}>
                            {item?.full_name ||
                              item?.user_agency?.first_name }
                          </td>
                          <td className={classes.setContent}>
                            {item?.last_name || item?.user_agency?.last_name}
                          </td>
                          <td>{item?.email || item?.user_agency?.email}</td>
                          <td>{item?.phone || item?.user_agency?.phone}</td>
                          <td>{item?.job_title}</td>
                          <td className={classes.tdStyle}>
                            {item?.quotation_received && (
                              <div className={classes.ListingIcons}>
                                <TiTick />
                              </div>
                            )}
                          </td>
                          <td className={classes.tdStyle}>
                            {item?.invoice_received && (
                              <div className={classes.ListingIcons}>
                                <TiTick />
                              </div>
                            )}
                          </td>
                          <td className={classes.tdStyle}>
                            {item?.order_confirmation && (
                              <div className={classes.ListingIcons}>
                                <TiTick />
                              </div>
                            )}
                          </td>
                          <td className={classes.tdStyle}>
                            {item?.delivery_email && (
                              <div className={classes.ListingIcons}>
                                <TiTick />
                              </div>
                            )}
                          </td>
                          <td>
                            {item.receive_property_updates && (
                              <div className={classes.ListingIcons}>
                                <TiTick />
                              </div>
                            )}
                          </td>
                          <td>
                            {item.receive_property_updates && (
                              <div className={classes.ListingIcons}>
                                <TiTick />
                              </div>
                            )}
                          </td>
                          <td>
                            {item?.user_id ? (
                              <div
                                className={classes.tdIcon}
                                onClick={() =>
                                  onClickSendPasswordLink(item?.user_id)
                                }
                              >
                                <IoIosSend />
                              </div>
                            ) : item?.is_user_not_possible ? (
                              <div className={classes.notUser}>
                                <FaUserSlash />
                              </div>
                            ) : (
                              <div
                                className={classes.tdIcon}
                                onClick={() => onClickCreateNewUser(item?.id)}
                              >
                                <FaUserPlus />
                              </div>
                            )}
                          </td>
                          <td
                            onClick={() =>
                              setContactFormShow({ data: item, status: true })
                            }
                          >
                            <div className={classes.ListingIcons}>
                              <img src={EditIcon} alt="" />
                            </div>
                          </td>
                          <td>
                            <button style={{ background: "#595f72", color: "white", padding: "2px", margin: "unset", width: "100%" }} onClick={() => handleLogin(item?.id)}>Login</button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className={classes.NoData}>
                        <td className={classes.LoaderWrap} colSpan={10}>
                          <h2>{t("no_data_found")}</h2>
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>

              <button
                onClick={() => setContactFormShow({ data: null, status: true })}
              >
                add contact <FaUserPlus />
              </button>
            </div>
          </div>
          <div className={classes.address}>
            <p>{t("Contact Log")}</p>
            <div className={classes.addressContainer}>
              <div className={classes.tableContainer}>
                <div className={classes.TableListngLayOut}>
                  <table>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>{t("DATE")}</th>
                      <th>{t("SUBJECT")}</th>
                      <th>{t("CONTACT")}</th>
                      <th>{t("ADDED BY")}</th>
                      <th></th>
                    </tr>
                    {console.log(contactLog,"contacttt999999999")}
                    {contactLog?.length > 0 ? (
                      contactLog?.map((item, index) => (
                        <tr
                          className={`${item?.hide_property == 1
                            ? classes.InvisibleCard
                            : null
                            } ${classes.searchContainer}`}
                        >


                          <td className={classes.tdIcon}>
                            {item?.pdf_file && (
                              <FaDownload
                                onClick={() => handleDownload(item?.pdf_file)}
                              />
                            )}
                          </td>
                          <td
                            className={`${classes.tdIcon}`}
                            style={{ paddingRight: "0px" }}
                            onMouseEnter={() => setIsHovered(index)}
                            onMouseLeave={() => setIsHovered("")}
                          >
                            {/* {item?.log && <TbMessageCircle2Filled />} */}
                            {item?.isCampaign ? <HiOutlineMailOpen /> : <TbMessageCircle2Filled />}
                            {!(item?.isCampaign) && isHovered === index && (
                              <div className={classes.preview}>
                                <p>{item?.emailContent}</p>
                              </div>
                            )}
                          </td>

                          <td style={{ borderBottom: "2px solid #e6eaf0 !important", borderTop: "2px solid #e6eaf0 !important" }}><span style={{ cursor: "pointer" }} onClick={() => {
                            console.log(item)
                            Swal.fire({

                              width: "70%",

                              html: item?.emailContent || "<div>No Data Found </div>",
                              showCloseButton: true,
                              showCancelButton: false,
                              showConfirmButton: false
 

                            });

                          }}>{(item?.isCampaign) ? <IoInformationCircleOutline size={18} /> : null}</span></td>
                          <td>
                            {moment(item?.created_at).format(
                              "DD/MM/YYYY HH:MM"
                            )}
                          </td>
                          <td>{item?.subject}</td>
                          <td>
                            {/* {console.log(item,"item123")} */}
                            {item?.contact?.full_name || item?.contact?.full_name ||
                              item?.contact?.user_agency?.first_name}
                          </td>
                          <td>{item?.added_by}</td>
                          <td
                            onClick={() =>
                              setLogFormShow({ data: item, status: true })
                            }
                          >
                            <div className={classes.ListingIcons}>
                              <img src={EditIcon} alt="" />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className={classes.NoData}>
                        <td className={classes.LoaderWrap} colSpan={4}>
                          <h2>{t("no_data_found")}</h2>
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
              <button
                onClick={() => setLogFormShow({ status: true, data: null })}
              >
                add
              </button>
            </div>
          </div>
        </div>
        <div className={classes.otherOrgDetailsWrapper}>
          <div className={classes.otherOrgDetails}>
            <div className={classes.OrgStatusWrap}>
              <div>
                <label>{t("org_created_at")}</label>
                <input
                  name={t("org_created_at")}
                  type="date"
                  className={classes.createdAt}
                  disabled
                  value={
                    (singleOrgData?.created_at &&
                      new Date(singleOrgData?.created_at)
                        .toISOString()
                        .split("T")[0]) ||
                    ""
                  }
                  onChange={handleInputChange}
                />
              </div>
              <div className={classes.StatusWrap}>
                <p>Profile Status:</p>
                <CustomDropdown
                  label="Profile Status"
                  id="Profile Status"
                  options={dummyData}
                  importData={importData}
                  ref={ProfileRef}
                  isOpen={isOpen.isOpen1}
                  setIsOpen={() => toggleDropdown("isOpen1")}
                  orgid={singleOrgData?.id}
                />
              </div>
              <div className={classes.StatusWrap}>
                <p>Import Status:</p>
                <CustomDropdown
                  label="Import Status"
                  id="Import Status"
                  options={importStatus}
                  importProfileStatus={importProfileStatus}
                  ref={ImportRef}
                  isOpen={isOpen.isOpen2}
                  setIsOpen={() => toggleDropdown("isOpen2")}
                  orgid={singleOrgData?.id}
                />
              </div>
              <div className={classes.StatusWrap}>
                <p>Financial Status:</p>
                <CustomDropdown
                  label="Financial Status"
                  id="Financial Status"
                  options={dummyData}
                  ref={FinancialRef}
                  isOpen={isOpen.isOpen3}
                  setIsOpen={() => toggleDropdown("isOpen3")}
                />
              </div>
              <div className={classes.StatusWrap}>
                <p>Logo : </p>
                {singleOrgData?.logo_image ? (
                  <p className={classes.okText}>Yes</p>
                ) : (
                  <p>No</p>
                )}{" "}
              </div>
              <div className={classes.StatusWrap}>
                <p>Banner : </p>
                {singleOrgData?.banner_image ? (
                  <p className={classes.okText}>Yes</p>
                ) : (
                  <p>No</p>
                )}{" "}
              </div>{" "}
              <div className={classes.StatusWrap}>
                <p>Description : </p>
                {singleOrgData?.description ? (
                  <p className={classes.okText}>Yes</p>
                ) : (
                  <p>No</p>
                )}{" "}
              </div>
              <button onClick={() => setViewProfile(true)}>View Profile</button>
            </div>
            <div className={classes.inputContainer}>
              <div>
                <label>{t("membership_status")}</label>
                <input
                  name={t("membership_status")}
                  value={formData[t("membership_status")] || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label>{t("Subscription")}</label>
                <input
                  className={classes.createdAt}
                  name={t("Subscription")}
                  disabled
                  value={
                    formData[t("Subscription")]?.subscription_detail
                      ?.product_detail?.name || "No Plan"
                  }
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label>{t("data_provider")}</label>
                <select
                  name={t("data_provider")}
                  onChange={handleInputChange}
                  value={formData[t("data_provider")]}
                >
                  <option value="" disabled selected>
                    {`${t("data_provider")}..`}
                  </option>
                  {dataProviderArray.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>
              </div>
              <div>
                <label>{t("data_provider_id")}</label>
                <input
                  type="number"
                  name={t("data_provider_id")}
                  value={formData[t("data_provider_id")] || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className={classes.inputContainer}>
              <div>
                <label>{t("banner_budget")}</label>
                <input
                  type="number"
                  name={t("banner_budget")}
                  value={formData[t("banner_budget")] || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <button onClick={onClickOfSaveBtn}>Save</button>
        </div>

        <div className={classes.branchAndClassContainer}>
          <div className={classes.branchSection}>
            <p>Branches</p>
            {state?.getNestedBranchData.map((mainBranch) => {
              return (
                <div className={classes.branchContainer}>
                  {subData(mainBranch, "branch")}
                </div>
              );
            })}
          </div>

          <div className={classes.branchSection}>
            <p>Classfications</p>
            {state?.getNestedClassificationData.map((mainClassification) => {
              return (
                <div className={classes.branchContainer}>
                  {subData(mainClassification, "class")}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* addressform */}

      <OffcanvasMain
        showcanvas={addressFormShow.status}
        placement={"end"}
        handleClose={() =>
          setAddressFormShow({ ...addressFormShow, status: false })
        }
        backdropClassName="abc"
        backgroundColor="true"
      >
        <AddressForm
          handleClose={() =>
            setAddressFormShow({ ...addressFormShow, status: false })
          }
          editData={addressFormShow.data}
        />
      </OffcanvasMain>

      {/* contactform */}

      <OffcanvasMain
        width={windowSize > 767 ? "lg" : "md"}
        showcanvas={contactFormShow.status}
        placement={"end"}
        handleClose={() =>
          setContactFormShow({ ...contactFormShow, status: false })
        }
        backdropClassName="abc"
        backgroundColor="true"
      >
        <ContactForm
          handleClose={() =>
            setContactFormShow({ ...contactFormShow, status: false })
          }
          editData={contactFormShow.data}
        />
      </OffcanvasMain>

      {/* logform */}

      <OffcanvasMain
        showcanvas={logFormShow.status}
        placement={"end"}
        handleClose={() => setLogFormShow({ ...logFormShow, status: false })}
        backdropClassName="abc"
        backgroundColor="true"
      >
        <LogForm
          handleClose={() => setLogFormShow({ ...logFormShow, status: false })}
          editData={logFormShow.data}
        />
      </OffcanvasMain>

      {/* viewProfile */}
      <OffcanvasMain
        width={windowSize > 767 ? "lg" : "md"}
        showcanvas={viewProfile}
        placement={"end"}
        handleClose={() => setViewProfile(false)}
        backgroundColor="true"
      >
        <ViewProfile handleClose={() => setViewProfile(false)} />
      </OffcanvasMain>
    </React.Fragment>
  );
};

export default DetailOrgPage;
