import React, { useState } from "react";
import classes from "./addinternalbanner.module.scss";
import { FaCode, FaUpload } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import { t } from "i18next";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import deleteIcon from "../../../assets/images/DeleteIcon.svg";
import {
  actionAddInternalBannerApiCall,
  actionCityInfoApiCall,
  actionDeleteInternalBannerApiCall,
  actionGetBannerUrlApiCall,
  actionGetDiscountAndExtraApiCall,
  actionOrganizationListApiCall,
  actionSaveBannerUrlData,
  actionSetLoader,
} from "../../../Redux/Actions";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";
import PriceFormatter from "../../../utils/PriceFormatter";
import {
  ConvertIntoFloatValue,
  disablePastDate,
} from "../../../utils/getSystem";
import Loader from "../Loader";
import { validateName, validateText } from "../../../utils/commonFunctions";
import ConfirmationModal from "../ConfirmationModal";
import CustomSelect from "../CustomSelect";
const AddInternalBanner = ({ editData, handleClose }) => {
  const fileInputRef = Array(5)
    .fill()
    .map(() => React.createRef());
  const state = useSelector((state) => state.CommonReducer);
  const loader = useSelector((state) => state.CommonReducer.loader);
  const discountData = useSelector(
    (state) => state.CommonReducer.saveDiscountAndExtrasData
  );
  const singleOrgData = useSelector(
    (state) => state.CommonReducer.singleOrgData
  );
  const [selectedImageState, setSelectedImageState] = useState();
  const bannerUrl = useSelector((state) => state.CommonReducer.bannerUrl);
  const todayDate = new Date();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateCountry = useSelector((state) => state.CommonReducer);

  const SelectItems = {
    name: "country_id",
    apiName: "country",
    placeholder: t("country"),
    options:
    stateCountry?.getCountryTable &&
    stateCountry?.getCountryTable?.map((p) => {
        return { label: p.name, value: p.id };
      }),
  };
  const [formData, setFormData] = useState({
    advertiser: {
      label: singleOrgData.name,
      value: singleOrgData.id,
    },
    payer: {
      label: singleOrgData.name,
      value: singleOrgData.id,
    },
    invoice_title: "",
    position: "",
    start_date: "",
    end_date: "",
    region_search: "",
    region_data: {},
    country_id: "",
    range: "",
    impressions: "",
    redirection_link: "",
    price: "",
    link_target: "",
    discount_extra: "",
    base_amount: "",
    base_price: "",
    base_vat: "",
    total_price: "",
    discount_extra_value: "",
    active: true,
  });
  const [errors, setErrors] = useState({
    advertiser: "",
    payer: "",
    invoice_title: "",
    position: "",
    start_date: "",
    country_id: "",
    end_date: "",
    impressions: "",
    redirection_link: "",
    price: "",
    link_target: "",
  });
  const [bannerSize, setBannerSize] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [removeData, setRemoveData] = useState({});
  const range = [
    "0 km",
    "1 km",
    "2 km",
    "5 km",
    "10 km",
    "15 km",
    "30 km",
    "50 km",
    "100 km",
  ];
  const linkTargetArray = ["Same tab", "New tab", "New Window"];
  const positionArray = [
    "Region",
    "Home Left",
    "Home Right",
    "Home Middle",
    "Home Ad 1",
    "Home Ad 2",
    "Home Ad 3",
    "Home Ad 4",
    "Dashboard 1",
    "Dashboard 2",
    "Dashboard 3",
  ];
  const [orgSearch, setOrgSearch] = useState("");
  const [show, setShow] = useState(false);
  const [isHovered, setIsHovered] = useState();
  const [selectedImage, setSlectedImage] = useState({ width: "", height: "" });
  const [selectedDiscountExtra, setDiscountExtra] = useState();
  useEffect(() => {
    validateForm(bannerSize, "image");
  }, [bannerSize]);

  useEffect(() => {
    dispatch(
      actionCityInfoApiCall({
        navigate,
        search: formData.region_search ? formData.region_search : "",
        agency_id: singleOrgData.id,
      })
    );
  }, [formData.region_search]);
  useEffect(() => {
    if (editData) {
      dispatch(actionSaveBannerUrlData(""));
    }
  }, [editData]);
  useEffect(() => {
    if (formData?.position) {
      dispatch(actionSetLoader(false));
      let data;
      if (bannerSize.length == 5 && formData?.position == "Region") {
        data = bannerSize;
      } else {
        data = mapPositionToSizeArray(formData?.position);
      }
      if (bannerUrl || editData) {
        if (
          formData?.position == editData?.position &&
          editData?.position != "Region"
        ) {
          if (editData.agency_banner_size.length > 0) {
            data = commonCodeToSetBannerSize([
              { ...data[0], id: editData.agency_banner_size[0].id },
            ]);
          }
        } else {
          data = commonCodeToSetBannerSize(data);
        }
      }
      setBannerSize(data);
    }
  }, [formData?.position, bannerUrl]);
  useEffect(() => {
    let impression = formData.impressions ? formData.impressions : 0;
    let price = formData.price ? formData.price : 0;
    let baseAmount = (+impression * +price) / 1000;
    let vat = 0.21;
    let basePrice = DiscountExtrasValue(baseAmount);
    let baseVat = +basePrice.value * +vat;

    setFormData({
      ...formData,
      base_amount: baseAmount,
      base_price: +basePrice.value,
      discount_extra_value: basePrice.discount_extra_value,
      base_vat: baseVat,
      total_price: +basePrice.value + +baseVat,
    });
  }, [formData.impressions, formData.price, formData.discount_extra]);

  useEffect(() => {
    const body = { navigate, valid: false };
    dispatch(actionGetDiscountAndExtraApiCall(body));
  }, []);
  useEffect(() => {
    if (editData) {
      console.log(editData, "editData1234");
      setFormData({
        discount_extra_value: editData.discount_and_extra_id,
        advertiser: {
          label: editData.advertiser_name,
          value: editData.advertiser_id,
        },
        payer: {
          label: editData.payer_name,
          value: editData.payer_id,
        },
        country_id: editData.country_id ? {
          label: editData.country?.name,
          value: editData.country_id
        } : "",
        invoice_title: editData.title,
        position: editData?.position,
        start_date: new Date(editData.start_date).toISOString().split("T")[0],
        end_date: new Date(editData.end_date).toISOString().split("T")[0],
        range: editData.range,
        impressions: editData.impressions,
        redirection_link: editData.redirection_link,
        price: +editData.price,
        link_target: editData.link_target,
        region_data: {
          label: editData.region_table,
          value: editData.region_table_id,
        },
        discount_extra: "",
        active:
          editData.status == "active"
            ? true
            : editData.status == "inactive"
            ? false
            : true,
      });
    }
  }, [editData]);
  useEffect(() => {
    const data = { page: 1, name: orgSearch, per_page: 10 };
    const body = { data, navigate };
    dispatch(actionOrganizationListApiCall(body));
  }, [orgSearch]);
  const commonCodeToSetBannerSize = (data) => {
    if (editData && editData.agency_banner_size) {
      if (bannerUrl) {
        data = data.map((item) => {
          if (
            bannerUrl.banner_size.includes(item.width) &&
            bannerUrl.banner_size.includes(item.height)
          ) {
            return {
              ...item,
              ...bannerUrl,
            };
          } else {
            return item;
          }
        });
      } else {
        if (editData?.position == formData?.position) {
          data = data.map((dataItem) => {
            const matchingBanner = editData.agency_banner_size.filter(
              (bannerItem) => {
                return (
                  bannerItem.banner_size.includes(dataItem.height) &&
                  bannerItem.banner_size.includes(dataItem.width)
                );
              }
            );
            if (matchingBanner.length > 0) {
              return {
                ...dataItem,
                ...matchingBanner[0],
              };
            } else {
              return dataItem;
            }
          });
        }
      }
    } else if (bannerUrl) {
      data = data.map((item) => {
        if (
          bannerUrl.banner_size.includes(item.width) &&
          bannerUrl.banner_size.includes(item.height)
        ) {
          return {
            ...item,
            ...bannerUrl,
          };
        } else {
          return item;
        }
      });
    }
    return data;
  };

  const mapPositionToSizeArray = (position) => {
    switch (position) {
      case "Region":
        return [
          {
            width: 160,
            height: 600,
            label: "Upload",
          },
          {
            width: 300,
            height: 250,
            label: "Upload",
          },
          {
            width: 336,
            height: 280,
            label: "Upload",
          },
          {
            width: 300,
            height: 600,
            label: "Upload",
          },
          {
            width: 970,
            height: 250,
            label: "Upload",
          },
        ];
      case "Home Ad 1":
        return [
          {
            width: 260,
            height: 208,
            label: "Upload",
          },
        ];
      case "Home Ad 2":
        return [
          {
            width: 260,
            height: 208,
            label: "Upload",
          },
        ];
      case "Home Ad 3":
        return [
          {
            width: 260,
            height: 208,
            label: "Upload",
          },
        ];
      case "Home Ad 4":
        return [
          {
            width: 260,
            height: 208,
            label: "Upload",
          },
        ];
      case "Home Left":
        return [
          {
            width: 613,
            height: 415,
            label: "Upload",
          },
        ];
      case "Home Right":
        return [
          {
            width: 613,
            height: 415,
            label: "Upload",
          },
        ];
      case "Home Middle":
        return [
          {
            width: 1306,
            height: 442,
            label: "Upload",
          },
        ];
      case "Dashboard 1":
        return [
          {
            width: 1595,
            height: 346,
            label: "Upload"
          }
        ];
      case "Dashboard 2":
        return [
          {
            width: 1595,
            height: 346,
            label: "Upload"
          }
        ];
      case "Dashboard 3":
        return [
          {
            width: 1595,
            height: 346,
            label: "Upload"
          }
        ];

      default:
        return [];
    }
  };
  const DiscountExtrasValue = (Total) => {
    if (selectedDiscountExtra) {
      let value = Total;
      let discount_extra_value;
      if (
        selectedDiscountExtra.type == "EXTRA" &&
        selectedDiscountExtra.price_type == "AMOUNT"
      ) {
        discount_extra_value = selectedDiscountExtra.price;
        value = Total + +selectedDiscountExtra.price;
      } else if (selectedDiscountExtra.type == "DISCOUNT") {
        if (selectedDiscountExtra.price_type == "PERCENTAGE") {
          let discountAmount = (Total * selectedDiscountExtra.percentage) / 100;
          discount_extra_value = discountAmount;
          value =
            ConvertIntoFloatValue(Total) -
            ConvertIntoFloatValue(discountAmount);
        } else {
          discount_extra_value = selectedDiscountExtra.price;
          value =
            ConvertIntoFloatValue(Total) -
            ConvertIntoFloatValue(discount_extra_value);
        }
      }
      return { value: value, discount_extra_value: discount_extra_value };
    }
    return { value: Total, discount_extra_value: 0 };
  };
  const onChangeOfTextArea = (e, height, width) => {
    let data = bannerSize.map((item) => {
      if (item.height == height && item.width == width)
        return { ...item, iframe: e.target.value };
      else return item;
    });
    setBannerSize(data);
    setSlectedImage({ ...selectedImage, iframe: e.target.value });
  };
  const setErrorFunc = (dataText, name) => {
    setErrors((prev) => ({
      ...prev,
      [name]: dataText?.isValid ? "" : dataText.message,
    }));
  };
  let validateForm = (value, name) => {
    let dataText;
    if (value !== "") {
      // if (name.includes("invoice_title")) {
      //   dataText = validateName(value, name);
      //   setErrorFunc(dataText, name);
      // } else
        if (name === "country_id" && formData.position !== "Region") {
      if (!value) {
        dataText = { isValid: false, message: "Country is required" };
        setErrorFunc(dataText, name);
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }
      if (name.includes("price") && value < 0) {
        dataText = { isValid: false, message: "price required" };
        setErrorFunc(dataText, name);
      } else if (name.includes("image")) {
        let imageArray = bannerSize.filter(
          (item) => item.url_original_file || item.iframe
        );
        if (imageArray.length == 0) {
          dataText = { isValid: false, message: "One image/iframe required" };
          setErrorFunc(dataText, name);
        } else {
          dataText = { isValid: false, message: "" };
          setErrorFunc(dataText, name);
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else {
      dataText = validateText(value, name);
      setErrorFunc(dataText, name);
    }
  };
  const onFocus = (event) => {
    let { name, value } = event.target;
    if (name != "range" || name != "discount_extra") {
      validateForm(value, name);
    }
  };

  const onBlur = (event) => {
    let { name, value } = event.target;
    if (name != "range" || name != "discount_extra") {
      validateForm(value, name);
    }
  };
  const showRemovePopup = (item, name, index) => {
    if (item.url_original_file && name == "iframe") {
      setRemoveData({ data: "image", ...item, index: index });
    } else if (item.iframe && name == "image") {
      setRemoveData({ data: "iframe", ...item, index: index });
    }
    setShowPopup(true);
  };
  const onClickOfCode = (item) => {
    setShow(true);
    setSlectedImage(item);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name != "range" || name != "discount_extra") {
      validateForm(value, name);
    }
    if (name == "discount_extra") {
      let data = discountData.filter((item) => item.id == value);
      if (data?.length > 0) {
        setDiscountExtra(data[0]);
      } else {
        setDiscountExtra("");
      }
    }
    if (name == "position" && bannerUrl) {
      dispatch(actionSaveBannerUrlData(""));
    }
  };
  const handleImageChange = (item, event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
        "image/svg+xml",
      ];
      if (allowedTypes.includes(file.type)) {
        const maxSize = 2 * 1024 * 1024;
        if (file.size > maxSize) {
          toast.error("Image size must not exceed 2 MB.");
        } else {
          const data = new FormData();
          data.append(`images[${item.width}*${item.height}]`, file);
          const body = { data, navigate };
          setSelectedImageState({ height: item.height, width: item.width });

          dispatch(actionGetBannerUrlApiCall(body));
        }
      } else {
        toast.error("Invalid file type. Please select a valid image file.");
      }
    }
  };
  const ClearImage = (height, width) => {
    setBannerSize(
      bannerSize.map((item) => {
        if (item.height == height && item.width == width) {
          return {
            ...item,
            image: null,
            url_original_file: null,
            original_file_name: null,
            file_type: null,
            banner_size: null,
          };
        }
        return item;
      })
    );
  };
  const handleDeleteBtn = () => {
    dispatch(
      actionDeleteInternalBannerApiCall({
        id: editData.id,
        closePopup: handleClose,
        agency_id: singleOrgData.id,
        navigate,
      })
    );
  };


  const handleSaveBtn = () => {
    validateForm(
      formData.invoice_title ? formData.invoice_title : "",
      "invoice_title"
    );
    validateForm(formData.position ? formData.position : "", "position");
    validateForm(formData.start_date ? formData.start_date : "", "start_date");
    validateForm(formData.end_date ? formData.end_date : "", "end_date");
    validateForm(
      formData.impressions ? formData.impressions : "",
      "impressions"
    );
    validateForm(
      formData.redirection_link ? formData.redirection_link : "",
      "redirection_link"
    );
    validateForm(formData.price, "price");
    validateForm(
      formData.link_target ? formData.link_target : "",
      "link_target"
    );
    validateForm(bannerSize, "image");

    if (
      Object.values(errors).every((value) => value == "") &&
      formData.impressions
    ) {
      const data = new FormData();
      data.append("advertiser_id", formData.advertiser.value);
      data.append("advertiser_name", formData.advertiser.label);
      data.append("payer_id", formData.payer.value);
      data.append("payer_name", formData.payer.label);
      data.append("title", formData.invoice_title);
      data.append("position", formData.position);
      data.append("start_date", formData.start_date);
      data.append("end_date", formData.end_date);
      if (formData?.position === "Region") {
        formData.region_data.label &&
          data.append("region_table", formData.region_data.label);
        formData.region_data.value &&
          data.append("region_table_id", formData.region_data.value);
        formData.range &&
          data.append(
            "range",
            formData.range.toString().includes("km")
              ? formData.range.toString().split("km")[0]
              : formData.range
          );
      }
      if (formData.position !== "Region" && formData.country_id) {
        data.append("country_id", formData.country_id.value);
      }
      data.append("impressions", formData.impressions);
      data.append("redirection_link", formData.redirection_link);
      data.append("link_target", formData.link_target);
      data.append("price", formData.price);
      data.append(
        "discount_and_extra_id",
        formData.discount_extra ? formData.discount_extra : 0
      );
      data.append("base_amount", formData.base_amount);
      data.append("base_price", formData.base_price);
      data.append(
        "base_vat",
        formData.base_vat ? +formData.base_vat.toFixed(2) : formData.base_vat
      );
      data.append("total_price", formData.total_price);
      data.append("status", formData.active == true ? "active" : "inactive");
      bannerSize.forEach((item, index) => {
        if (item.banner_size || item.iframe) {
          item.id && data.append(`images[${index}][id]`, item.id);
          data.append(
            `images[${index}][banner_size]`,
            item.banner_size ? item.banner_size : `${item.width}*${item.height}`
          );
          data.append(
            `images[${index}][iframe]`,
            item.iframe ? item.iframe : ""
          );
          data.append(
            `images[${index}][original_file_name]`,
            item.original_file_name ? item.original_file_name : ""
          );
          data.append(
            `images[${index}][file_type]`,
            item.file_type ? item.file_type : ""
          );
          data.append(
            `images[${index}][url_original_file]`,
            item.url_original_file ? item.url_original_file : ""
          );
        } else if (item.id && item.url_original_file == null) {
          data.append(`images[${index}][id]`, item.id);
          data.append(`images[${index}][banner_size]`, item.banner_size);
          data.append(`images[${index}][iframe]`, null);
          data.append(
            `images[${index}][original_file_name]`,
            item.original_file_name
          );
          data.append(`images[${index}][file_type]`, item.file_type);
          data.append(
            `images[${index}][url_original_file]`,
            item.url_original_file
          );
        }
      });
      dispatch(
        actionAddInternalBannerApiCall({
          agency_id: singleOrgData.id,
          data,
          edit: editData ? editData.id : false,
          closePopup: handleClose,
          navigate,
        })
      );
    }
  };
  const onClickOfYes = () => {
    if (removeData.data == "iframe") {
      let data = bannerSize.map((item) => {
        if (item.height == removeData.height && item.width == removeData.width)
          return { ...item, iframe: "" };
        else return item;
      });
      setBannerSize(data);
      fileInputRef[removeData.index].current.click();
    } else if (removeData.data == "image") {
      ClearImage(removeData.height, removeData.width);
      onClickOfCode(removeData);
    }
  };
  const onClickOfUploadIcon = (item, e, index) => {
    if (!item.iframe) {
      fileInputRef[index].current.click();
    } else {
      showRemovePopup(item, "image", index);
    }
  };
  return (
    <div className={classes.AddInternalBannerContainer}>
      <h3>
        {editData ? "Edit" : "Add"} <span>Internal</span> Banner
      </h3>
      <div className={classes.AddInternalBanner}>
        <div className={classes.DataContainer}>
          <label>Advertiser</label>
          <div className={`${classes.SearchBar}`}>
            <BiSearch />
            <Select
              defaultValue={formData.advertiser}
              placeholder={<div>Advertiser</div>}
              className="basic-single"
              classNamePrefix="select"
              isClearable={false}
              isSearchable={true}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              name="color"
              onInputChange={(data) => setOrgSearch(data)}
              options={
                state?.orgListData &&
                state?.orgListData?.map((p) => {
                  return { label: p.name, value: p.id };
                })
              }
              onChange={(option) => {
                setFormData({ ...formData, ["advertiser"]: option });
                setErrors({ ...errors, ["advertiser"]: "" });
              }}
            />
          </div>
          <p className={classes.errorText}>{errors.advertiser}</p>
          <label>(Invoice) Title</label>
          <input
            name="invoice_title"
            value={formData.invoice_title}
            onChange={handleInputChange}
            onBlur={onBlur}
            onFocus={onFocus}
          />
          <p className={classes.errorText}>{errors.invoice_title}</p>
          <div className={classes.inputContainer}>
            <div>
              <label>Start</label>
              <div className={classes.inputbox}>
                <input
                  type="date"
                  name="start_date"
                  min={disablePastDate()}
                  value={formData.start_date}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  onChange={handleInputChange}
                />
              </div>
              <p className={classes.errorText}>{errors.start_date}</p>
            </div>
            <div>
              <label>End</label>
              <div className={classes.inputbox}>
                <input
                  type="date"
                  name="end_date"
                  min={formData.start_date}
                  value={formData.end_date}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  onChange={handleInputChange}
                />
              </div>
              <p className={classes.errorText}>{errors.end_date}</p>
            </div>
          </div>
          <label>Impressions</label>
          <input
            type="number"
            name="impressions"
            onBlur={onBlur}
            onFocus={onFocus}
            value={formData.impressions}
            onChange={handleInputChange}
          />
          <p className={classes.errorText}>{errors.impressions}</p>
          <label>Price per 1000 Impressions ( € )</label>
          <input
            type="number"
            name="price"
            value={formData.price}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={handleInputChange}
          />
          <p className={classes.errorText}>{errors.price}</p>
          <label>Discount/Extra's</label>
          <select
            name="discount_extra"
            value={formData.discount_extra}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={handleInputChange}
          >
            <option value="" disabled selected>
              {`${t("Discount/Extra's")}...`}
            </option>
            <option value="">{`Empty Select...`}</option>
            {discountData &&
              discountData?.length > 0 &&
              discountData
                .filter((item) => todayDate < new Date(item.valid_until))
                .map((item) => <option value={item.id}>{item.name}</option>)}
          </select>
          <div className={classes.TotalContainer}>
            <div className={classes.inputbox}>
              <p>Total</p>
              <p>
                {PriceFormatter(
                  formData.price ? ConvertIntoFloatValue(formData.price) : 0,
                  2,
                  "€ ",
                  true
                ).replace(",-", "")}{" "}
                *{" "}
                {formData.impressions
                  ? PriceFormatter(formData.impressions, 0, "€ ", true).replace(
                      ",-",
                      ""
                    )
                  : 0}{" "}
                ={" "}
                {PriceFormatter(
                  ConvertIntoFloatValue(formData.base_amount),
                  2,
                  "€ ",
                  true
                ).replace(",-", "")}
              </p>
            </div>
            {selectedDiscountExtra && (
              <div className={classes.inputbox}>
                <p>{selectedDiscountExtra.name}</p>
                <p>
                  {PriceFormatter(
                    formData.discount_extra_value,
                    2,
                    "€ ",
                    true
                  ).replace(",-", "")}
                </p>
              </div>
            )}
          </div>
          <div className={classes.TotalContainer}>
            <div className={classes.inputbox}>
              <p>Ex. VAT</p>
              <p>
                {" "}
                {PriceFormatter(formData.base_price, 2, "€ ", true).replace(
                  ",-",
                  ""
                )}
              </p>
            </div>
            <div className={classes.inputbox}>
              <p>21% VAT</p>
              <p>
                {" "}
                {PriceFormatter(formData.base_vat, 2, "€ ", true).replace(
                  ",-",
                  ""
                )}
              </p>
            </div>
          </div>
          <div className={`${classes.TotalContainer} ${classes.InclContainer}`}>
            <div className={classes.inputbox}>
              <p>Incl. VAT</p>
              <p>
                {" "}
                {PriceFormatter(formData.total_price, 2, "€ ", true).replace(
                  ",-",
                  ""
                )}
              </p>
            </div>
          </div>
        </div>
        <div className={classes.DataContainer}>
          <label>Payer</label>
          <div className={classes.SearchBar}>
            <BiSearch />
            <Select
              defaultValue={formData.advertiser}
              className="basic-single"
              classNamePrefix="select"
              isClearable={false}
              isSearchable={true}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              name="color"
              onInputChange={(data) => setOrgSearch(data)}
              options={
                state?.orgListData &&
                state?.orgListData?.map((p) => {
                  return { label: p.name, value: p.id };
                })
              }
              onChange={(option) => {
                setFormData({ ...formData, ["payer"]: option });
                setErrors({ ...errors, ["payer"]: "" });
              }}
            />
          </div>
          <p className={classes.errorText}>{errors.payer}</p>
          <label>Position </label>
          <select
            name="position"
            value={formData?.position}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={handleInputChange}
          >
            <option value="" disabled selected>
              {`${t("position")}...`}
            </option>
            {positionArray.map((item) => (
              <option>{item}</option>
            ))}
          </select>
          <p className={classes.errorText}>{errors.position}</p>
          {formData?.position == "Region" && (
            <div className={classes.inputWrapper}>
              <div className={classes.regionContainer}>
                <label>Region</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={
                    editData && {
                      label: editData.region_table,
                      value: editData.region_table_id,
                    }
                  }
                  isClearable={false}
                  isSearchable={true}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  name="color"
                  onInputChange={(data) =>
                    setFormData({ ...formData, ["region_search"]: data })
                  }
                  options={
                    state?.saveCityInfoData &&
                    state?.saveCityInfoData?.map((p) => {
                      return {
                        label:
                          p.type == "NEIGHBOURHOOD" || p.type == "DISTRICT"
                            ? `${p.name}(${t(p.type)}, ${p?.city?.name})`
                            : `${p.name}(${t(p.type)})`,
                        value: p.id,
                      };
                    })
                  }
                  onChange={(option) => {
                    setFormData({ ...formData, ["region_data"]: option });
                  }}
                />

                <p className={classes.errorText}>{errors.region_data}</p>
              </div>
              <div>
                <label> Range </label>
                <select
                  name="range"
                  value={formData.range}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  onChange={handleInputChange}
                >
                  <option value="" disabled selected>
                    {`${t("Range")}...`}
                  </option>
                  {range.map((item) => (
                    <option>{item}</option>
                  ))}
                </select>
                <p className={classes.errorText}>{errors.range}</p>
              </div>
            </div>
          )}
          {(formData.position == "") || (formData.position !== "Region") && (
            <div className={`${classes.Text} ${classes.SelectText}`}>
            <label>{SelectItems.placeholder}</label>
            <CustomSelect
              defaultInputValue={editData ? true : false}
              value={
                editData && {
                  label: editData[SelectItems.placeholder.toLowerCase()]?.name,
                  value: editData[SelectItems.placeholder.toLowerCase()]?.id,
                }
              }
              item={SelectItems}
              apiName={SelectItems.apiName}
              isInputChange={true}
              headerSearch={formData}
              setHeaderSearch={setFormData}
              errors={errors}
              setErrors={setErrors}
              isClearable={true}
            />
            {errors[SelectItems.name] && (
              <p className={classes.error}>{errors[SelectItems.name]}</p>
            )}
          </div>
          )}
          <label>Redirection Link</label>
          <input
            name="redirection_link"
            value={formData.redirection_link}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={handleInputChange}
          />
          <p className={classes.errorText}>{errors.redirection_link}</p>
          <label>Link Target</label>
          <select
            name="link_target"
            value={formData.link_target}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={handleInputChange}
          >
            <option value="" disabled selected>
              {`${t("Link Target")}...`}
            </option>
            {linkTargetArray.map((item) => (
              <option>{item}</option>
            ))}
          </select>
          <p className={classes.errorText}>{errors.link_target}</p>
          <div className={classes.ToggleWrap}>
            <p>{t("Status")}</p>
            <label className={classes.Switch}>
              <input
                type="checkbox"
                name="active"
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    active: event.target.checked,
                  });
                }}
                checked={formData.active}
              />
              <span className={classes.Slider} />
            </label>
          </div>
          <label>Banner sizes</label>
          {bannerSize && bannerSize?.length > 0 ? (
            bannerSize.map((item, index) => {
              return (
                <div
                  className={classes.bannerContainer}
                  onMouseEnter={() => setSlectedImage(item)}
                >
                  <div className={classes.searchContainer}>
                    {isHovered === index && (
                      <div className={classes.preview}>
                        <div
                          className={`${classes.innerpreview}  ${classes.bannerInner}`}
                        >
                          {item.url_original_file ? (
                            <>
                              <img src={item.url_original_file} />
                              <div
                                className={classes.cross}
                                onClick={() =>
                                  ClearImage(item.height, item.width)
                                }
                              >
                                <IoClose />
                              </div>
                            </>
                          ) : (
                            <p>Example</p>
                          )}
                        </div>
                      </div>
                    )}
                    <BiSearch
                      onMouseEnter={() => setIsHovered(index)}
                      onMouseLeave={() => setIsHovered(index)}
                    />
                  </div>
                  <p>
                    {item.width} x {item.height}
                  </p>
                  <div
                    className={classes.icon}
                    onClick={() => {
                      if (!item.url_original_file) {
                        onClickOfCode(item);
                      } else {
                        showRemovePopup(item, "iframe", index);
                      }
                    }}
                  >
                    <FaCode />
                  </div>
                  <button
                    className={`${classes.icon}`}
                    onClick={(e) => onClickOfUploadIcon(item, e, index)}
                  >
                    <FaUpload />
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef[index]}
                    id={`file-upload${item.height}${item.width}`}
                    accept=".jpg, .jpeg, .png,.webp,.svg"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handleImageChange(item, e);
                    }}
                    onBlur={onBlur}
                    onFocus={onFocus}
                  />
                  <label
                    htmlFor={`file-upload${item.height}${item.width}`}
                    className={classes.ImageLabel}
                  >
                    {item.label}
                    {loader &&
                    selectedImageState?.height == item.height &&
                    selectedImageState?.width == item.width ? (
                      <div className={classes.loaderContainer}>
                        <Loader banner={true} />
                      </div>
                    ) : (
                      item.url_original_file && "  New"
                    )}
                  </label>
                </div>
              );
            })
          ) : (
            <p className={classes.simpleText}>Need to Select Position</p>
          )}
          <p className={classes.errorText}>{errors.image}</p>
        </div>
      </div>
      <div className={classes.BtnContainer}>
        {editData ? (
          <>
            <button className={classes.cancel} onClick={handleDeleteBtn}>
              <img src={deleteIcon} alt="" /> Delete
            </button>
            <button className={classes.save} onClick={handleSaveBtn}>
              Save
            </button>
          </>
        ) : (
          <>
            <button className={classes.cancel} onClick={handleClose}>
              Cancel
            </button>
            <button className={classes.save} onClick={handleSaveBtn}>
              Add
            </button>
          </>
        )}
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Code voor Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            style={{ width: "100%", height: "200px", marginTop: "10px" }}
            value={selectedImage?.iframe}
            onChange={(e) =>
              onChangeOfTextArea(e, selectedImage.height, selectedImage.width)
            }
          />
        </Modal.Body>
      </Modal>
      <ConfirmationModal
        showmodel={showPopup}
        handleClose={() => setShowPopup(false)}
        onClickYesButton={onClickOfYes}
        title={`You are about to delete data of ${removeData?.data}`}
        size={"md"}
        newDesign={true}
        btnName={"Ok"}
        MainTitle={"Remove Data"}
      ></ConfirmationModal>
    </div>
  );
};

export default AddInternalBanner;
