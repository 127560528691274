import { ACTION_DELETE_TAG_SUCCESS } from "../Actions";

const inititalState = {
  saveBranchData: [],
  saveLoginUserDetails: "",
  userListData: [],
  orgListData: [],
  singleOrgData: "",
  savedTags: "",
  token: "",
  savedAudience: "",
  savedSubjects: "",
  createNewtask: "",
  savedRecipients: "",
  downloadedRecipients: "",
  savedAllCampaigns: "",
  savedTemplates: "",
  savedNewTemplates: "",
  savedNewCampaigns: "",
  singleUserData: "",
  totalUserCount: "",
  totalOrgCount: "",
  totalListCount: "",
  totalLangCount: "",
  perPageUserCount: "",
  perPageOrgCount: "",
  perPageListCount: "",
  perPageLangCount: "",
  userloghistorydata: "",
  saveOrganizationListSingleUserData: "",
  SavePropertyListdata: [],
  usedEmailFlag: "",
  alreadyUsedEmailData: "",
  saveSinglePropertyData: "",
  SaveSinglePropertyViewData: [],
  savePriceHistoryData: [],
  SaveLangListData: [],
  SaveLangLabelListData: [],
  loader: false,
  orgloader: false,
  language: "en",
  saveLangFilterData: "",
  saveListFilterData: "",
  saveUserFilterData: "",
  saveOrgFilterData: "",
  saveSupplierFilterData: "",
  saveSingleBannerData: "",
  bannerListData: [],
  saveBannerFilterData: "",
  totalBannerCount: "",
  perPageBannerCount: "",
  emailListData: [],
  saveEmailFilterData: "",
  totalEmailCount: "",
  perPageEmailCount: "",
  perPageSupplierCount: "",
  linkSend: "",
  saveRentPrefixData: "",
  saveRentPostfixData: "",
  saveSellPrefixData: "",
  saveSellPostfixData: "",
  saveStatusData: "",
  saveEmployeeJobTitle: [],
  saveSiteSettings: "",
  saveMasterTableColumn: "",
  saveMasterTableData: "",
  perPageMasterTableCount: "",
  totalMasterTableCount: "",
  saveMasterTableFilterData: "",
  //discount
  saveDiscountAndExtrasData: "",
  saveSingleDiscountAndExtrasData: "",
  totalDiscountCount: "",
  saveDiscountFilterData: "",
  //subsciption
  saveSubscriptionData: "",
  saveSingleSubscriptionData: "",
  totalSubscriptionCount: "",
  saveSubscriptionFilterData: "",
  saveSubscriptionListForOrg: "",
  saveAllPaymentOption: "",

  //Magazines
  saveMagazineFilterData: "",

  // organization
  getCities: "",
  getZipCode: [],

  getStatusForBranch: "",

  // system management --> branches and classification

  getBranchTable: [],
  getBranchData: [],

  getNestedBranchData: [],

  getNestedClassificationData: [],

  getClassificationTable: [],
  totalBranchCount: "",
  perPageBranchCount: "",
  totalClassficationCount: "",
  perPageClassficationCount: "",
  getCountryTable: [],
  totalCountrieCount: "",
  getMunicipalityTable: [],
  totalMunicipalityCount: "",
  getDistrictTable: [],
  totalDistrictCount: "",
  getNeighbourhoodTable: [],
  totalNeighbourhoodCount: "",
  getCountryPartTable: [],
  totalCountryPartCount: "",
  getCityData: [],
  totalCityCount: "",
  getProvinceData: [],
  totalProvinceCount: "",
  getZipcodeData: [],
  totalZipcodeCount: "",
  contactLogData: [],
  buttonloader: false,
  autoSuggestedCity: "",
  saveTranslationColumn: [],
  totalTranslationCount: "",
  saveTranslationColumnFilter: {},
  saveTranslationLanguageFilter: {},
  saveAddressData: [],
  saveInternalBannerdata: [],
  saveCityInfoData: [],
  saveVatData: [],
  totalVatDataCount: "",
  bannerUrl: "",
  saveAllClassifications: [],
  saveAllProvince: [],
  saveAllCity: [],

  //TAGS & TEMPLATE
  updatedTags: "",
  updatedTemplates: "",
  //broker
  brokerButtons:[]
};

function CommonReducer(state = inititalState, action) {
  switch (action.type) {
    case "SAVE_LOGIN_USER_DATA":
      return { ...state, saveLoginUserDetails: action.payload };
    case "SAVE_USER_LIST_DATA":
      return { ...state, userListData: action.payload };

    case "SAVE_ORGANIZATION_LIST_DATA":
      return { ...state, orgListData: action.payload };
    case "SAVE_SINGLE_ORGANIZATION_DATA":
      return { ...state, singleOrgData: action.payload };
    case "SAVE_SINGLE_USER_DATA":
      return { ...state, singleUserData: action.payload };
    case "TOTAL_USER_COUNT":
      return { ...state, totalUserCount: action.payload };
    case "PER_PAGE_USER_COUNT":
      return { ...state, perPageUserCount: action.payload };
    case "TOTAL_ORG_COUNT":
      return { ...state, totalOrgCount: action.payload };
    case "PER_PAGE_ORG_COUNT":
      return { ...state, perPageOrgCount: action.payload };
    case "SAVE_LOG_HISTORY_DATA":
      return { ...state, userloghistorydata: action.payload };
    case "TOTAL_LIST_COUNT":
      return { ...state, totalListCount: action.payload };
    case "PER_PAGE_LIST_COUNT":
      return { ...state, perPageListCount: action.payload };
    case "SAVE_ORG_LIST_IN_SINGLE_USER_DATA":
      return { ...state, saveOrganizationListSingleUserData: action.payload };
    case "SAVE_PROPERTY_LIST_DATA":
      return { ...state, SavePropertyListdata: action.payload };
    case "USED_EMAIL_FLAG":
      return { ...state, usedEmailFlag: action.payload };
    case "ALREAFY_USED_EMAIL_DATA":
      return { ...state, alreadyUsedEmailData: action.payload };
    case "SAVE_SINGLE_PROPERTY_DETAIL":
      return { ...state, saveSinglePropertyData: action.payload };
    case "SAVE_LOCATION_LIST_DATA":
      return { ...state, locationListData: action.payload };
    case "SAVE_SUPPLIER_TYPES_DATA":
      return { ...state, supplierTypesData: action.payload };
    case "SAVE_PRICETABLE_TYPES_DATA":
      return { ...state, priceTableTypesData: action.payload };
    case "SAVE_PRICELINE_UNITS_DATA":
      return { ...state, priceLineUnitsData: action.payload };
    case "SAVE_TITLE_LIST_DATA":
      return { ...state, titleListData: action.payload };
    case "SAVE_ADVERTISING_SUPPLIER_LIST_DATA":
      return { ...state, advSupplierListData: action.payload };
    case "SAVE_PROPERTY_VIEWS_DETAIL":
      return { ...state, SaveSinglePropertyViewData: action.payload };
    case "SAVE_PRICE_HISTORY_DATA":
      return { ...state, savePriceHistoryData: action.payload };
    case "SAVE_LANG_LIST_DATA":
      return { ...state, SaveLangListData: action.payload };
    case "SAVE_LANG_LABEL_LIST_DATA":
      return { ...state, SaveLangLabelListData: action.payload };
    case "TOTAL_LANG_COUNT":
      return { ...state, totalLangCount: action.payload };
    case "PER_PAGE_LANG_COUNT":
      return { ...state, perPageLangCount: action.payload };
    case "SET_LOADER":
      return { ...state, loader: action.payload };
    case "ORG_LOADER":
      return { ...state, orgloader: action.payload };
    case "SET_LANGUAGE":
      return { ...state, language: action.payload };
    case "SAVE_LANG_FILTER_DATA":
      return { ...state, saveLangFilterData: action.payload };
    case "SAVE_USER_FILTER_DATA":
      return { ...state, saveUserFilterData: action.payload };
    case "SAVE_LIST_FILTER_DATA":
      return { ...state, saveListFilterData: action.payload };
    case "SAVE_ORG_FILTER_DATA":
      return { ...state, saveOrgFilterData: action.payload };
    case "SAVE_SUPPLIER_FILTER_DATA":
      return { ...state, saveSupplierFilterData: action.payload };
    case "SAVE_SINGLE_BANNER_DATA":
      return { ...state, saveSingleBannerData: action.payload };
    case "SAVE_BANNER_DATA":
      return { ...state, bannerListData: action.payload };
    case "SAVE_BANNER_FILTER_DATA":
      return { ...state, saveBannerFilterData: action.payload };
    case "TOTAL_BANNER_COUNT":
      return { ...state, totalBannerCount: action.payload };
    case "PER_PAGE_BANNER_COUNT":
      return { ...state, perPageBannerCount: action.payload };
    case "SAVE_EMAIL_DATA":
      return { ...state, emailListData: action.payload };
    case "SAVE_EMAIL_FILTER_DATA":
      return { ...state, saveEmailFilterData: action.payload };
    case "TOTAL_EMAIL_COUNT":
      return { ...state, totalEmailCount: action.payload };
    case "PER_PAGE_EMAIL_COUNT":
      return { ...state, perPageEmailCount: action.payload };
    case "PER_PAGE_SUPPLIER_COUNT":
      return { ...state, perPageSupplierCount: action.payload };
    case "SAVE_SINGLE_EMAIL_DATA":
      return { ...state, saveSingleUserData: action.payload };
    case "UPDATE_SINGLE_USER_DATA":
      return {
        ...state,
        singleUserData: { ...state.singleUserData, ["status"]: action.payload },
      };
    case "LINK_SEND":
      return { ...state, linkSend: action.payload };
    case "SAVE_RENT_PREFIX_DATA":
      return { ...state, saveRentPrefixData: action.payload };
    case "SAVE_RENT_POSTFIX_DATA":
      return { ...state, saveRentPostfixData: action.payload };
    case "SAVE_SELL_PREFIX_DATA":
      return { ...state, saveSellPrefixData: action.payload };
    case "SAVE_SELL_POSTFIX_DATA":
      return { ...state, saveSellPostfixData: action.payload };
    case "SAVE_STATUS_DATA":
      return { ...state, saveStatusData: action.payload };
    case "ACTION_SAVE_EMPLOYEE_JOB_TITLE":
      return { ...state, saveEmployeeJobTitle: action.payload };
    case "ACTION_SAVE_GET_SITE_SETTINGS":
      return { ...state, saveSiteSettings: action.payload };
    case "ACTION_SAVE_MASTER_TABLE_GET_TRANSLATION_COLUMN":
      return { ...state, saveMasterTableColumn: action.payload };
    case "ACTION_SAVE_GET_TRANSLATION":
      return { ...state, saveMasterTableData: action.payload };
    case "PER_PAGE_MASTER_TABLE_COUNT":
      return { ...state, perPageMasterTableCount: action.payload };
    case "SAVE_TOTAL_MASTER_TABLE_COUNT":
      return { ...state, totalMasterTableCount: action.payload };
    case "SAVE_MASTER_TABLE_FILTER_DATA":
      return { ...state, saveMasterTableFilterData: action.payload };
    case "ACTION_SAVE_DISCOUNT_AND_EXTRAS":
      return { ...state, saveDiscountAndExtrasData: action.payload };
    case "ACTION_SAVE_SINGLE_DISCOUNT_DATA":
      return { ...state, saveSingleDiscountAndExtrasData: action.payload };
    case "ACTION_SAVE_SUBSCIPTION":
      return { ...state, saveSubscriptionData: action.payload };
    case "ACTION_SAVE_SINGLE_SUBSCIPTION_DATA":
      return { ...state, saveSingleSubscriptionData: action.payload };
    case "SAVE_SUBSCIPTION_FILTER_DATA":
      return { ...state, saveSubscriptionFilterData: action.payload };
    case "TOTAL_SUBSCIPTION_COUNT":
      return { ...state, totalSubscriptionCount: action.payload };
    case "SAVE_DISCOUNT_FILTER_DATA":
      return { ...state, saveDiscountFilterData: action.payload };
    case "TOTAL_DISCOUNT_COUNT":
      return { ...state, totalDiscountCount: action.payload };
    case "ACTION_SAVE_ALL_SUBSCIPTION_LIST":
      return { ...state, saveSubscriptionListForOrg: action.payload };
    case "ACTION_SAVE_ALL_PAYMENT_OPTION":
      return { ...state, saveAllPaymentOption: action.payload };
    case "ACTION_SAVE_ALL_PROVINCE_OPTION":
      return { ...state, saveAllProvince: action.payload };
    case "ACTION_SAVE_ALL_CITY_OPTION":
      return { ...state, saveAllCity: action.payload };
    case "ACTION_SAVE_ALL_CLASSIFICATIONS_OPTION":
      return { ...state, saveAllClassifications: action.payload };

    case "ACTION_SAVE_CITIES_DATA":
      return { ...state, getCities: action.payload };
    case "ACTION_SAVE_ZIP_CODE":
      return { ...state, getZipCode: action.payload };

    // chanes
    case "ACTION_SAVE_BRANCH_TABLE_DATA":
      return { ...state, getBranchTable: action.payload };
    case "ACTION_SAVE_BRANCH_AUDIENCE_DATA":
      return { ...state, getBranchData: action.payload };
    case "ACTION_GET_BRANCH_AUDIENCE_DATA":
      return { ...state, getgetBranchData: action.payload };
    case "ACTION_SAVE_BRANCH":
      return { ...state, saveBranchData: action.payload };

    case "ACTION_STORE_BRANCH_NESTED_DATA":
      return { ...state, getNestedBranchData: action.payload };

    case "ACTION_GLBOAL_STATE_FOR_CLOSE_CANVAS":
      return { ...state, getStatusForBranch: action.payload };

    case "ACTION_STORE_NESTED_CLASSIFICATION_DATA":
      return { ...state, getNestedClassificationData: action.payload };

    case "ACTION_STORE_TABLE_CLASSIFICATION_DATA":
      return { ...state, getClassificationTable: action.payload };
    case "TOTAL_BRANCH_COUNT":
      return { ...state, totalBranchCount: action.payload };
    case "PER_PAGE_BRANCH_COUNT":
      return { ...state, perPageBranchCount: action.payload };
    case "TOTAL_CLASSIFICATION_COUNT":
      return { ...state, totalClassficationCount: action.payload };
    case "PER_PAGE_CLASSIFICATION_COUNT":
      return { ...state, perPageClassficationCount: action.payload };

    case "ACTION_SAVE_COUNTRIES_TABLE_DATA":
      return { ...state, getCountryTable: action.payload };

    case "ACTION_SAVE_TOTAL_COUNTRIES_COUNT":
      return { ...state, totalCountrieCount: action.payload };

    case "ACTION_SAVE_MUNICIPALITY_TABLE_DATA":
      return { ...state, getMunicipalityTable: action.payload };

    case "ACTION_SAVE_TOTAL_MUNICIPALITY_COUNT":
      return { ...state, totalMunicipalityCount: action.payload };

    case "ACTION_SAVE_DISTRICT_TABLE_DATA":
      return { ...state, getDistrictTable: action.payload };

    case "ACTION_SAVE_TOTAL_DISTRICT_COUNT":
      return { ...state, totalDistrictCount: action.payload };

    case "ACTION_SAVE_NEIGHBOURHOOD_TABLE_DATA":
      return { ...state, getNeighbourhoodTable: action.payload };

    case "ACTION_SAVE_TOTAL_NEIGHBOURHOOD_COUNT":
      return { ...state, totalNeighbourhoodCount: action.payload };

    case "ACTION_SAVE_COUNTRYPART_TABLE_DATA":
      return { ...state, getCountryPartTable: action.payload };

    case "ACTION_SAVE_TOTAL_COUNTRYPART_COUNT":
      return { ...state, totalCountryPartCount: action.payload };
    case "ACTION_SAVE_CITY_DATA":
      return { ...state, getCityData: action.payload };

    case "ACTION_SAVE_TOTAL_CITY_COUNT":
      return { ...state, totalCityCount: action.payload };

    case "ACTION_SAVE_PROVINCE_DATA":
      return { ...state, getProvinceData: action.payload };

    case "ACTION_SAVE_TOTAL_PROVINCE_COUNT":
      return { ...state, totalProvinceCount: action.payload };

    case "ACTION_SAVE_ZIPCODE_DATA":
      return { ...state, getZipcodeData: action.payload };

    case "ACTION_SAVE_TOTAL_ZIPCODE_COUNT":
      return { ...state, totalZipcodeCount: action.payload };

    case "ACTION_SAVE_CONTACT_LOG_DATA":
      return { ...state, contactLogData: action.payload };
    case "SET_BUTTON_LOADER":
      return { ...state, buttonloader: action.payload };
    case "ACTION_SAVE_AUTO_SUGGEST_CITY_DATA":
      return { ...state, autoSuggestedCity: action.payload };
    case "ACTION_SAVE_TRANSLATION_COLUMN":
      return { ...state, saveTranslationColumn: action.payload };
    case "ACTION_SAVE_TOTAL_TRANSLATION_COLUMN_COUNT":
      return { ...state, totalTranslationCount: action.payload };
    case "ACTION_SAVE_TRANSLATION_COLUMN_FILTER":
      return { ...state, saveTranslationColumnFilter: action.payload };
    case "ACTION_SAVE_TRANSLATION_LANGUAGE_FILTER":
      return { ...state, saveTranslationLanguageFilter: action.payload };
    case "SAVE_ADDRESSES_DATA":
      return { ...state, saveAddressData: action.payload };
    case "ACTION_SAVE_INTERNAL_BANNER_DATA":
      return { ...state, saveInternalBannerdata: action.payload };
    case "ACTION_CITY_INFO_SAVE_DATA":
      return { ...state, saveCityInfoData: action.payload };
    case "ACTION_SAVE_VAT_DATA":
      return { ...state, saveVatData: action.payload };
    case "ACTION_SAVE_TOTAL_VAT_DATA_COUNT":
      return { ...state, totalVatDataCount: action.payload };
    case "ACTION_SAVE_BANNER_URL_DATA":
      return { ...state, bannerUrl: action.payload };
    case "ACTION_SAVE_BANNER_CAMPAIGNS_LISTING": {
      return { ...state, bannerCampaignsData: action.payload };
    }
    case "ACTION_SAVE_BANNER_CAMPAIGN_DATA": {
      return { ...state, bannerCampaignData: action.payload };
    }
    case "ACTION_SAVE_INVOICE_LISTING": {
      return { ...state, invoicesData: action.payload };
    }
    case "ACTION_SAVE_INVOICE_DATA": {
      return { ...state, invoiceData: action.payload };
    }
    case "ACTION_SAVE_TOKEN": {
      return { ...state, token: action.payload };
    }

    case "ACTION_SAVE_CREATED_TAGS":
      return { ...state, savedTags: action.payload };
    case "ACTION_SAVE_CREATED_AUDIENCE":
      return { ...state, savedAudience: action.payload };
    case "ACTION_SAVE_SUBJECT":
      return { ...state, savedSubjects: action.payload };
    case "ACTION_SAVE_CREATED_RECIPIENTS":
      return { ...state, savedRecipients: action.payload };
    case "ACTION_SAVE_DOWNLOADED_RECIPIENTS":
      return { ...state, downloadedRecipients: action.payload };
    case "ACTION_SAVE_ALL_CAMPAIGN":
      return { ...state, savedAllCampaigns: action.payload };
    case "ACTION_EMPTY_CONTENT":
      return { ...state, savedSubjects: "" };
    case "ACTION_CREATE_NEW":
      return { ...state, createNewtask: action.payload };
    case "ACTION_EMPTY_AUDIENCE_DATA":
      return { ...state, savedAudience: "" };
    case "ACTION_SAVE_CREATED_TEMPLATES":
      return { ...state, savedTemplates: action.payload };
    case "ACTION_SAVE_NEW_CREATED_TEMPLATES":
      return { ...state, savedNewTemplates: action.payload };
    case "ACTION_SAVE_NEW_CREATED_CAMPAIGN":
      return { ...state, savedNewCampaigns: action.payload };

    case "ACTION_SAVE_ALL_TAGS_OPTION":
      return { ...state, saveAllTags: action.payload };

    case "ACTION_UPDATE_TAGS_SUCCESS":
      return {
        ...state,
        saveAllTags: { ...state.saveAllTags, ["tag"]: action.payload },
      };

    case "ACTION_DELETE_TAG_SUCCESS":
      // Filter out the deleted tag from the state
      const updatedTags = state.saveAllTags.filter(
        (tag) => tag.id !== action.payload
      );
      return {
        ...state,
        saveAllTags: { ...state.saveAllTags, updatedTags },
      };

    // SUPPLIERS
    case "ACTION_SAVE_SUPPLIER_DATA": {
      return { ...state, supplierData: action.payload };
    }

    case "ACTION_SAVE_SUPPLIERS_DATA": {
      return { ...state, suppliersData: action.payload };
    }

    case "ACTION_UPDATE_TEMPLATES_SUCCESS":
      return {
        ...state,
        savedTemplates: {
          ...state.savedTemplates,
          ["templates"]: action.payload,
        },
      };

    case "ACTION_DELETE_TEMPLATES_SUCCESS":
      // Filter out the deleted tag from the state
      const updatedTemplates = state.savedTemplates.filter(
        (template) => template.id !== action.payload
      );
      return {
        ...state,
        savedTemplates: { ...state.savedTemplates, updatedTemplates },
      };

    // MAGAZINES
    case "ACTION_SAVE_MAGAZINE_DATA":
      return { ...state, saveMagazineData: action.payload };
    case "ACTION_SAVE_MAGAZINES_DATA":
      console.log("Save Magazines Data", action.payload);
      return { ...state, saveMagazinesData: action.payload };
    case "ACTION_SAVE_MAGAZINE_AD_KINDS_DATA":
      console.log("saveMagazineAdKindsData", action.payload);
      return { ...state, saveMagazineAdKindsData: action.payload };
    case "SAVE_MAGAZINE_TYPES_DATA":
      return { ...state, magazineTypesData: action.payload };
    case "ACTION_SAVE_BROKERDATA_BUTTONS" :
      return { ...state,brokerButtons: action.payload }

    default:
      return state;
  }
}

export default CommonReducer;
