import classes from "./bannerorgpage.module.scss";
import { t } from "i18next";
import { useState } from "react";
import SearchIcon from "../../../assets/images/SearchIcon.svg";
import EditIcon from "../../../assets/images/EditLogo.svg";
import OffcanvasMain from "../../common/Offcanvas";
import AddInternalBanner from "../../common/AddInternalBanner";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionGetInternalBannerApiCall } from "../../../Redux/Actions";
import moment from "moment";

const BannerOrgPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.CommonReducer);
  const [activeInternal, setActiveInternal] = useState(false);
  const [activeExternal, setActiveExternal] = useState(false);
  const [bannerShow, setBannerShow] = useState({ status: false, data: null });
  const [internalBannerData, setInternalBannerData] = useState(
    state?.saveInternalBannerdata
  );
  const tempInvoiceData = [
    {
      invoice_id: 234045,
      invoice_title: "Brink",
      region: "Assen +20km",
      start: "02/10/2023",
      end: "02/12/2023",
      payer: "Voorbeeled BV.",
      views: 32,
      clicks: 3,
      status: "Inactive",
    },
    {
      invoice_id: 234045,
      invoice_title: "Brink",
      region: "Assen +20km",
      start: "02/10/2023",
      end: "02/12/2023",
      payer: "Voorbeeled BV.",
      views: 32,
      clicks: 3,
      status: "Inactive",
    },
    {
      invoice_id: 234045,
      invoice_title: "Brink",
      region: "Assen +20km",
      start: "02/10/2023",
      end: "02/12/2023",
      payer: "Voorbeeled BV.",
      views: 32,
      clicks: 3,
      status: "Inactive",
    },
  ];
  const [internalBannerSearch, setInternalBannerSearch] = useState();
  const singleOrgData = useSelector(
    (state) => state.CommonReducer.singleOrgData
  );
  useEffect(() => {
    setInternalBannerData(state?.saveInternalBannerdata);
  }, [state?.saveInternalBannerdata]);

  useEffect(() => {
    dispatch(
      actionGetInternalBannerApiCall({
        navigate,
        agency_id: singleOrgData.id,
      })
    );
  }, []);
  useEffect(() => {
    if (internalBannerSearch) {
      setInternalBannerData(
        state?.saveInternalBannerdata.filter((item) =>
          item?.title.toLowerCase().includes(internalBannerSearch.toLowerCase())
        )
      );
    } else if (activeInternal) {
      setInternalBannerData(
        state?.saveInternalBannerdata.filter((item) => item.status == "active")
      );
    } else {
      setInternalBannerData(state?.saveInternalBannerdata);
    }
  }, [internalBannerSearch, activeInternal]);
  return (
    <div className={classes.BannerOrgPage}>
      {" "}
      <p>
        <span className={classes.pinkText}>Internal</span> Organisation Banners
      </p>
      <div className={classes.tableContainer}>
        <div className={classes.TableListngLayOut}>
          <div className={classes.SearchBar}>
            <div>
              <img src={SearchIcon} alt="" />
              <input
                type="search"
                placeholder="Search in banners"
                onChange={(e) => setInternalBannerSearch(e.target.value)}
              />
            </div>
            <div className={classes.activeOnly}>
              <span> Only Show Active</span>
              <label className={classes.Switch}>
                <input
                  type="checkbox"
                  checked={activeInternal}
                  onChange={() => setActiveInternal(!activeInternal)}
                />
                <span className={classes.Slider} />
              </label>
            </div>
          </div>
          <table>
            <tr>
              <th>{t("INFO")}</th>
              <th>{t("(INVOICE) TITLE")}</th>
              <th>{t("COUNTRY")}</th>
              <th>{t("REGION")}</th>
              <th>{t("START")}</th>
              <th>{t("END")}</th>
              <th>{t("PAYER")}</th>
              <th>{t("VIEWS")}</th>
              <th>{t("CLICKS")}</th>
              <th>{t("STATUS")}</th>
              <th></th>
            </tr>
            {internalBannerData?.length > 0 ? (
              internalBannerData?.map((item) => {
                console.log(item, "item123");
                return (
                  <tr>
                    <td>
                      <div className={classes.ListingIcons}>
                        <img src={SearchIcon} alt="" />
                      </div>
                    </td>
                    <td>{item?.title}</td>
                    <td>{item?.country?.name ? item?.country?.name : "N/A"}</td>
                    <td>{item.region_table ? item.region_table : "N/A"}</td>
                    <td>{moment(item.start_date).format("DD/MM/YYYY")}</td>
                    <td>{moment(item.end_date).format("DD/MM/YYYY")}</td>
                    <td>{item.payer_name}</td>
                    <td>{item.views}</td>
                    <td>{item.clicks}</td>
                    <td>{item.status}</td>
                    <td
                      onClick={() =>
                        setBannerShow({ status: true, data: item })
                      }
                    >
                      <div className={classes.ListingIcons}>
                        <img src={EditIcon} alt="" />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className={classes.NoData}>
                <td className={classes.LoaderWrap} colSpan={10}>
                  <h2>{t("no_data_found")}</h2>
                </td>
              </tr>
            )}
          </table>
        </div>
        <div className={classes.AddUseAsSection}>
          <button onClick={() => setBannerShow({ status: true, data: null })}>
            {t("add")}
          </button>
        </div>
      </div>{" "}
      <p>
        <span className={classes.pinkText}>External</span> Organisation Banners
      </p>
      <div className={classes.tableContainer}>
        <div className={classes.TableListngLayOut}>
          <div className={classes.SearchBar}>
            <div>
              <img src={SearchIcon} alt="" />
              <input
                type="search"
                placeholder="Search in banners"
                // onChange={(e) => setSubscriptionSearch(e.target.value)}
              />
            </div>
            <div className={classes.activeOnly}>
              <span> Only Show Active</span>
              <label className={classes.Switch}>
                <input
                  type="checkbox"
                  checked={activeExternal}
                  onChange={() => setActiveExternal(!activeExternal)}
                />
                <span className={classes.Slider} />
              </label>
            </div>
          </div>
          <table>
            <tr>
              <th>{t("INFO")}</th>
              <th>{t("(INVOICE) TITLE")}</th>
              <th>{t("REGION")}</th>
              <th>{t("START")}</th>
              <th>{t("END")}</th>

              <th>{t("PAYER")}</th>
              <th>{t("VIEWS")}</th>
              <th>{t("CLICKS")}</th>
              <th>{t("STATUS")}</th>
              <th></th>
            </tr>
            {tempInvoiceData.length > 0 ? (
              tempInvoiceData.map((item) => {
                return (
                  <tr>
                    <td>
                      <div className={classes.ListingIcons}>
                        <img src={SearchIcon} alt="" />
                      </div>
                    </td>
                    <td>{item.invoice_title}</td>
                    <td>{item.region}</td>
                    <td>{item.start}</td>
                    <td>{item.end}</td>
                    <td>{item.payer}</td>
                    <td>{item.views}</td>
                    <td>{item.clicks}</td>
                    <td>{item.status}</td>
                    <td>
                      <div
                        className={classes.ListingIcons}
                        // onClick={() => onClickofEditIcon(item.id)}
                      >
                        <img src={EditIcon} alt="" />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className={classes.NoData}>
                <td className={classes.LoaderWrap} colSpan={6}>
                  <h2>{t("no_data_found")}</h2>
                </td>
              </tr>
            )}
          </table>
        </div>
        <div className={classes.AddUseAsSection}>
          <button onClick={() => {}}>{t("add")}</button>
        </div>
      </div>
      {/* addInternalBanner */}
      <OffcanvasMain
        width="lg"
        showcanvas={bannerShow.status}
        placement={"end"}
        handleClose={() => setBannerShow({ status: false, data: null })}
        backdropClassName="abc"
        backgroundColor="true"
      >
        <AddInternalBanner
          handleClose={() => setBannerShow({ status: false, data: null })}
          editData={bannerShow.data}
        />
      </OffcanvasMain>
    </div>
  );
};

export default BannerOrgPage;
