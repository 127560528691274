import "./App.scss";
import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./Router/Router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import APIUrl from "./API/APIUrl";

function App() {

  // useEffect(() => {
  //   console.log = function() {}
  // }, []);

  useEffect(() => {
    if (
      window.location.href.includes("https://wm-admin.testyourapp.online") ||
      window.location.href.includes("localhost")
    ) {
      console.log(window.location.href,"itshould be  https://wm-admin.testyourapp.online or localhost")
      localStorage.setItem("Platform", "DEV");
    } else if (
      window.location.href.includes("https://www.admin-test.woningmarkt.nl/")
    ) {
      console.log(window.location.href,"itshould be  https://www.admin-test.woningmarkt.nl")
      localStorage.setItem("Platform", "UAT");
    }
    else {
      console.log(window.location.href,"itshould be  Prod url")
      localStorage.setItem("Platform", "PROD");
    }
    console.log(`${APIUrl.backendUrl} this is the environment variable from app.js`)
  }, []);
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        icon={false}
      />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
