import React, { useEffect } from "react";
import MainLayount from "../common/MainLayout";
import classes from "./systemmanagement.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  actionGetSiteSettingsApiCall,
  actionSaveTranslationColumnFilter,
  actionSaveTranslationLanguageFilter,
} from "../../Redux/Actions";
import SystemManagementHeader from "../common/SystemManagementHeader";
import EmialIcon from "../../assets/icons/EmailIcon.svg";
import SubscriptionsIcon from "../../assets/icons/SubscriptionsIcon.svg";
import JobTypesIcon from "../../assets/icons/JobTypesIcon.svg";
import LanguageIcon from "../../assets/icons/LanguageIcon.svg";
import StatusManagment from "../../assets/icons/StatusManagement.svg";
import DiscountAndExtraIcon from "../../assets/icons/DiscountAndExtraIcon.svg";
import ClassificationIcon from "../../assets/icons/ClassificationsIcon.svg";
import BranchesIcon from "../../assets/icons/BranchesIcon.svg";
import earth from "../../assets/icons/earth.png";
import municipality from "../../assets/icons/municipality.png";
import district from "../../assets/icons/district.png";
import neighbourhood from "../../assets/icons/neighbourhood.png";
import zipcodeIcon from "../../assets/icons/zipcodeIcon.png";
import cityIcon from "../../assets/icons/cityIcon.png";
import usermanagementIcon from '../../assets/icons/usermanagementIcon.svg'
import { LiaLanguageSolid } from "react-icons/lia";
import { HiReceiptTax } from "react-icons/hi";
const SystemManagementMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actionGetSiteSettingsApiCall({ navigate }));
  }, []);

  let systemData = [
    {
      icon: EmialIcon,
      text: t("email"),
      name: "email",
    },
    { icon: JobTypesIcon, text: t("job_types"), name: "job_types" },
    {
      icon: SubscriptionsIcon,
      text: t("subscriptions"),
      name: "subscriptions",
    },
    {
      icon: LanguageIcon,
      text: t("language"),
      name: "language",
    },
    {
      icon: StatusManagment,
      text: t("status"),
      name: "status",
    },
    {
      icon: DiscountAndExtraIcon,
      text: t("discountsand_extras"),
      name: "discountsand_extras",
    },
    {
      icon: ClassificationIcon,
      text: t("classification"),
      name: "classification",
    },
    {
      icon: BranchesIcon,
      text: t("Branches"),
      name: "Branches",
    },
    {
      icon: earth,
      text: t("countries"),
      name: "countries",
    },
    {
      icon: municipality,
      text: t("municipality"),
      name: "municipality",
    },
    {
      icon: district,
      text: t("district"),
      name: "district",
    },
    {
      icon: neighbourhood,
      text: t("neighbourhood"),
      name: "neighbourhood",
    },
    {
      icon: cityIcon,
      text: t("city_p"),
      name: "city",
    },
    {
      icon: zipcodeIcon,
      text: t("zipcode"),
      name: "zipcode",
    },
    {
      icon: earth,
      text: t("country_part"),
      name: "countrypart",
    },
    {
      icon: municipality,
      text: t("province"),
      name: "province",
    },
    {
      icon: LiaLanguageSolid,
      text: t("translation"),
      name: "translation",
    },
    {
      icon: HiReceiptTax,
      text: t("vat"),
      name: "vat",
    },
    {
      icon: usermanagementIcon,
      text: "Broker Dashboard",
      name: "broker",
    },
  ];

  const clickHandler = (title) => {
    switch (title) {
      case "email":
        return navigate("/email-management");
      case "language":
        return navigate("/language-management");
      case "setting_management":
        return navigate("/setting-management");
      case "job_types":
        return navigate(`/mastertable-management/${"job_title"}`);
      case "status":
        return navigate(`/mastertable-management/${"status"}`);
      case "discountsand_extras":
        return navigate("/discount-and-extra-management");
      case "subscriptions":
        return navigate("/subscription-management");
      case "classification":
        return navigate("/classification-management");
      case "Branches":
        return navigate("/Branches-management");
      case "countries":
        return navigate("/countries-management");
      case "municipality":
        return navigate("/municipality-management");
      case "district":
        return navigate("/district-management");
      case "neighbourhood":
        return navigate("/neighbourhood-management");
      case "city":
        return navigate("/city-management");
      case "zipcode":
        return navigate("/zipcode-management");
      case "countrypart":
        return navigate("/countrypart-management");
      case "province":
        return navigate("/province-management");
      case "translation":
        return navigate("/translation-management");
      case "vat":
        return navigate("/vat-management");
      case "broker":
        return navigate('/broker-dashboard-admin')

      default:
        break;
    }
  };
  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.LanguageMain}>
          <SystemManagementHeader />
          <div className={classes.SystemManagementSection}>
            <div className={classes.SystemManagementWrap}>
              {systemData.length > 0 &&
                systemData.map((item, index) => {
                  return (
                    <div
                      className={classes.SectionWrap}
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        clickHandler(item.name);
                        if (
                          item.name == "translation" ||
                          item.name == "job_types"
                        ) {
                          dispatch(actionSaveTranslationColumnFilter({}));
                          dispatch(actionSaveTranslationLanguageFilter({}));
                        }
                      }}
                    >
                      <div className={classes.IconWrap}>
                        {item.name == "translation" || item.name == "vat" ? (
                          <item.icon />
                        ) : (
                          <img src={item.icon} />
                        )}
                      </div>
                      <p>{item.text}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </MainLayount>
    </React.Fragment>
  );
};

export default SystemManagementMain;
