import MainLayout from '../common/MainLayout'
import BrokerHeader from '../common/BrokerHeader';
import OffcanvasMain from '../common/Offcanvas';
import BrokerPartForm from '../common/BrokerPartForm';
import classes from './brokerDashboard.module.scss';
import { actionGetBrokerButtonApiCall } from '../../Redux/Actions';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userEditorIcon from '../../assets/icons/userEditorIcon.svg';
import { actionDeleteBrokerButtonDataAPiCall } from '../../Redux/Actions';
import { useNavigate } from 'react-router-dom';
import Loader from '../common/Loader';

const BrokerDashboardMain = () => {
  const [openCanvas, setOpenCanvas] = useState(false);
  const dispatch = useDispatch();
  const divref = useRef([]);
  const listRef = useRef(null);
  const [ind, setInd] = useState("");
  const [show, setShow] = useState(false);
  const [editData, setEditData] = useState({});
  const navigate = useNavigate()
  console.log("the editData is", editData);

  const BrokerButton = useSelector((state) => state.CommonReducer.brokerButtons);
  const loader = useSelector((state) => state.CommonReducer.loader);
  console.log("the loader is", loader);


  useEffect(() => {
    dispatch(actionGetBrokerButtonApiCall())
  }, [])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleScroll = (index) => {
    let ref = divref.current[index];
    const y = ref?.getBoundingClientRect().top;
    if (y > 303) {
      divref.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleDelete = (item) => {
    //call delete API
    console.log("the deleteAPI is", item);
    dispatch(actionDeleteBrokerButtonDataAPiCall({ id: item?.id, navigate }))

  };

  return (
    <MainLayout>
      <BrokerHeader />
      <div className={classes.fildBox}>
        <button
          onClick={() => {
            setOpenCanvas(true)
            setEditData(null)
          }}
          type="submit">
          Add
        </button>
      </div>
      <div className={classes.buttonMain}>
        <div className={classes.buttonSection}>
          <table>
            <thead>
              <tr>
                <th>Button Name</th>
                <th>Icon</th>
                <th>Status</th>
                <th>Link</th>
                <th>Menu Type</th>
                <th></th>

              </tr>
            </thead>
            <tbody>
              {loader === true ? (
                <tr>
                  <td className={classes.LoaderWrap} colSpan={6}>
                    <Loader />
                  </td>
                </tr>
              ) : BrokerButton?.length > 0 ? (
                BrokerButton.map((item, index) => (
                  <tr ref={(e) => (divref.current[index] = e)} key={index}>
                    <td>{item?.name}</td>
                    <td>
                      <img src={item.icon} height={52} width={89} />
                    </td>
                    <td>
                      <span className={`${classes.status} ${item.isActive ? classes.active : classes.inactive}`}>
                        {item.isActive ? 'Active' : 'Inactive'}
                      </span>
                    </td>
                    <td>{item?.link}</td>
                    <td>
                      <span className={`${classes.menuType} ${classes[item.menuType]}`}>
                        {item.menuType}
                      </span>
                    </td>
                    <td className={classes.userEditor}>
                      <img
                        src={userEditorIcon}
                        onClick={() => {
                          handleScroll(index);
                          if (ind !== index) {
                            setShow(!show);
                            setInd(index);
                          } else {
                            setInd("");
                          }
                        }}
                      />
                      {show && ind === index && (
                        <div
                          ref={listRef}
                          className={classes.EditorWrap}
                          onClick={() => setShow(!show)}
                          style={
                            BrokerButton.length - 1 > index
                              ? {}
                              : BrokerButton.length > 4
                                ? { top: "-100px" }
                                : {}
                          }
                        >
                          <ul>
                            <li
                              onClick={() => {
                                setOpenCanvas(true);
                                setEditData(item);
                              }}
                            >
                              Edit Buttons
                            </li>
                            <li onClick={() => handleDelete(item)}>
                              Delete Buttons
                            </li>
                          </ul>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className={classes.LoaderWrap} colSpan={6}>
                    <h2>No Data Found</h2>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <OffcanvasMain
        showcanvas={openCanvas}
        placement={"end"}
        backgroundColor="true"
        handleClose={() => {
          setOpenCanvas(false);
        }}
      >
        <BrokerPartForm
          editData={editData}
          handleClose={() => {
            setOpenCanvas(false);
          }}
        />
      </OffcanvasMain>
    </MainLayout>
  )
}

export default BrokerDashboardMain;