import React, { useEffect, useRef } from "react";
import './custommodel.scss';

const CommonModal = ({
  isVisible,
  onClose,
  classHere = "",
  title,
  loading = false,
  children,
  OnClickOutSideCloseModel = true,
  className = "",
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!isVisible) {
      return;
    }
    if (!OnClickOutSideCloseModel) {
      return;
    }
    document.body.style.overflow = "hidden";

    const handleKeyDown = (e) =>
      e.key === "Escape" && !loading && onClose();
    const handleMouseDown = (e) =>
      ref?.current &&
      !ref.current.contains(e.target) &&
      !loading &&
      onClose();

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleMouseDown);
      document.body.style.overflow = "auto";
    };
  }, [ref, OnClickOutSideCloseModel, onClose, isVisible, loading]);

  if (!isVisible) return null;

  return (
    <div className={`modal-overlay ${classHere}`}>
      <div
        ref={ref}
        className={`modal-content ${className}`}
      >
        <div className="modal-header">
          <h3 className="modal-title">{title}</h3>
          <button
            onClick={() => {
              !loading && onClose();
            }}
            className="modal-close"
            aria-label="Close"
          >
            <span className="close-icon">X</span>
          </button>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default CommonModal;