import React from "react";
import classes from "./sidebar.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainLogon from "../../../assets/images/loginlogo.svg";
import deshboardIcon from "../../../assets/icons/deshboardIcon.svg";
import usermanagementIcon from "../../../assets/icons/usermanagementIcon.svg";
import organisationmanagementIocn from "../../../assets/icons/organisationmanagementIocn.svg";
import listingmanagementIcon from "../../../assets/icons/listingmanagementIcon.svg";
import systemmanagementIcon from "../../../assets/icons/systemmanagementIcon.svg";
import systemmanagementPinkIcon from "../../../assets/icons/systemmanagementPinkIcon.svg";
import listingpinkIcon from "../../../assets/icons/listingpinkicon.svg";
import logoutIcon from "../../../assets/icons/logout.png";
import orgpinkiconIcon from "../../../assets/icons/orgpinkicon.svg";
import userpinkIcon from "../../../assets/icons/userpinkicon.svg";
import EuroIcon from "../../../assets/icons/EuroIcon.svg";
import Mailicon from "../../../assets/icons/envelope-solid.png";
import EuroPinkIcon from "../../../assets/icons/EuroPinkIcon.svg";
import Greyemail from "../../../assets/icons/mailgrey.png";
import { useDispatch } from "react-redux";
import { actionLogoutApiCall } from "../../../Redux/Actions";
import BannerManagementIcon from "../../../assets/icons/BannerManagementIcon.svg";
import BannerManagementPinkIcon from "../../../assets/icons/BannerManagementPinkIcon.svg";
import { toast } from "react-toastify";
import supplierIcon from "../../../assets/icons/man.svg";
import supplierPinkIcon from "../../../assets/icons/manpink.svg";
import magazineIcon from "../../../assets/icons/Magazine.svg";
import magazinePinkIcon from "../../../assets/icons/MagazinePink.svg";

const sidebarItems = [
  {
    label: "Dashboard",
    icon: deshboardIcon,
    path: "/dashboard",
    pinkIcon: null,
  },
  {
    label: "User Management",
    icon: usermanagementIcon,
    path: "/user-management",
    pinkIcon: userpinkIcon,
    activeName: "user",
  },
  {
    label: "Organisation Management",
    icon: organisationmanagementIocn,
    path: "/organisation-management",
    pinkIcon: orgpinkiconIcon,
    activeName: "org",
  },
  {
    label: "Listing Management",
    icon: listingmanagementIcon,
    path: "/listing-management",
    pinkIcon: listingpinkIcon,
    activeName: "list",
  },
  {
    label: "Banner Management",
    icon: BannerManagementIcon,
    path: "/banner-management",
    pinkIcon: BannerManagementPinkIcon,
    activeName: "banner",
  },
  {
    label: "Banner Campaign Management",
    icon: BannerManagementIcon,
    path: "/campaign-management",
    pinkIcon: BannerManagementPinkIcon,
    activeName: "campaign",
  },
  {
    label: "Financial Management",
    icon: EuroIcon,
    path: "/financial-management",
    pinkIcon: EuroPinkIcon,
    activeName: "financial",
  },
  {
    label: "Supplier Management",
    icon: supplierIcon,
    path: "/supplier-management",
    pinkIcon: supplierPinkIcon,
    activeName: "supplier",
  },
  {
    label: "Magazine Management",
    icon: magazineIcon,
    path: "/magazine-management",
    pinkIcon: magazinePinkIcon,
    activeName: "magazine",
  },
  {
    label: "System Management",
    icon: systemmanagementIcon,
    path: "/system-management",
    pinkIcon: systemmanagementPinkIcon,
    activePaths: [
      "/system-management",
      "/email-management",
      "/subscription-management",
      "/mastertable-management/status",
      "/mastertable-management/job_title",
      "/language-management",
      "/discount-and-extra-management",
      "/classification-management",
      "/Branches-management",
      "/countries-management",
      "/municipality-management",
      "/district-management",
      "/neighbourhood-management",
      "/city-management",
      "/zipcode-management",
      "/countrypart-management",
      "/province-management",
      "/translation-management",
      "/vat-management",
    ],
  },
  {
    label: "Email Marketing",
    icon: Greyemail,
    path: "/email-marketing",
    pinkIcon: Mailicon,
    activeName: "email-marketing",
  },
];

const SideBar = () => {
  const userdata = JSON.parse(localStorage.getItem("userData"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const handleLogout = () => {
    const body = { navigate };
    dispatch(actionLogoutApiCall(body));
  };

  const handleClick = (item) => {
    if (item.label === "Dashboard") {
      toast.success("Will be added soon");
    } else {
      navigate(item.path);
    }
  };
  return (
    <React.Fragment>
      <div className={classes.SideBarSection}>
        <div className={classes.SideBarWrap}>
          <div className={classes.LogoMain}>
            <img src={MainLogon} alt="MainLogo" />
          </div>
          <div className={classes.UserInfo}>
            <div className={classes.UserName}>
              <span>{t("hello")},</span>
              <p>{userdata?.full_name ? userdata.full_name : "UserName"}</p>
            </div>
            <div className={classes.UserPosition}>
              <p>{t("super_admin")}</p>
            </div>
          </div>
          <div className={classes.SidebarLinkWrap}>
            <ul>
              {sidebarItems.map((item) => {
                const isActive =
                  item.label === "System Management"
                    ? item.activePaths.includes(pathname)
                    : pathname.includes(item.activeName);
                return (
                  <li
                    key={item.label}
                    onClick={() => handleClick(item)}
                    style={{
                      color: isActive ? "#DF1681" : "#505050",
                      transition: "color 0.3s ease",
                    }}
                  >
                    <img
                      src={item.pinkIcon}
                      alt={item.label}
                      className={`${classes.icon} "active"
                        }`}
                      style={
                        isActive ? { display: "block" } : { display: "none" }
                      }
                    />
                    <img
                      src={item.icon}
                      alt={item.label}
                      className={`${classes.icon}`}
                      style={
                        isActive ? { display: "none" } : { display: "block" }
                      }
                    />
                    <span>{t(item.label)}</span>
                  </li>
                );
              })}
              <li onClick={handleLogout}>
                <img src={logoutIcon} alt="Logout" />
                {t("logout")}
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.VersionSection}>
          <p>{t("version")}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SideBar;
