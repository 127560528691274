import React, { useState } from "react";
import classes from "./magazinedetailsheader.module.scss";
import { useTranslation } from "react-i18next";
import { GiHamburgerMenu } from "react-icons/gi";
import OffcanvasMain from "../Offcanvas";
import SideBar from "../SideBar";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const MagazineDetailsHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sidebar, setSideBar] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(window.innerWidth);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [window]);
  const sidebarShow = () => {
    setSideBar(true);
  };
  const handleClose = () => {
    setSideBar(false);
  };

  return (
    <React.Fragment>
      <OffcanvasMain showcanvas={sidebar} handleClose={handleClose}>
        <SideBar />
      </OffcanvasMain>
      <div className={classes.containerHeader}>
        <div className={classes.CanvasWarp}>
          <button onClick={sidebarShow}>
            <GiHamburgerMenu />
          </button>
        </div>
        <div className={classes.BreadCrumbLayout}>
          <p
            className={classes.semiBoldLightText}
            onClick={() => navigate("/magazine-management")}
          >
            {t("magazine_management")}
          </p>
          <p className={classes.hyphen}>-</p>
          <p className={classes.semiBoldMedText}>{t("view_magazine")}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MagazineDetailsHeader;
