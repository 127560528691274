import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../common/MainLayout";
import classes from "./magazinemanagementmain.module.scss";
import MagazineManagementHeader from "../common/MagazineManagementHeader";
import Loader from "../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import ModalMain from "../common/Modal";
import {
  actionViewMagazineApiCall,
  actionDeleteMagazineApiCall,
  actionGetMagazineMetaDataApiCall,
} from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";

const MagazineManagementMain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.CommonReducer.loader);

  const [showInfoScreen, setShowInfoScreen] = useState(false);
  const [showTitle, setShowTitle] = useState();
  const [showContent, setShowContent] = useState();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const magazinesData = useSelector(
    (state) => state.CommonReducer.saveMagazinesData
  );

  console.log("magazinesData", magazinesData);

  const [show, setShow] = useState(false);
  const [ind, setInd] = useState("");
  const listRef = useRef(null);
  const divref = useRef([]);

  const handleScroll = (index) => {
    let ref = divref.current[index];
    const y = ref.getBoundingClientRect().top;
    if (y > 304) {
      divref.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const onCLickViewMagazine = (id) => {
    const data = {
      id: id,
    };
    const body = { data, navigate };
    dispatch(actionViewMagazineApiCall(body));
  };

  const onCLickDeleteMagazine = (id) => {
    const data = {
      id: id,
    };
    const body = { data, navigate };
    dispatch(actionDeleteMagazineApiCall(body));
  };

  useEffect(() => {
    dispatch(actionGetMagazineMetaDataApiCall({ navigate }));

    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const platform = localStorage.getItem("Platform");

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.BannerMain}>
          <MagazineManagementHeader />
          <div className={classes.BannerListingSection}>
            <table>
              <thead>
                <tr>
                  <th>{t("name_mag_c")}</th>
                  <th>{t("members_mag_c")}</th>
                  <th>{t("objects_mag_c")}</th>
                  <th>{t("pages_mag_c")}</th>
                  <th>{t("ad_kinds_mag_c")}</th>
                  <th>{t("type_mag_c")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loader === true ? (
                  <tr>
                    <td className={classes.LoaderWrap} colSpan={6}>
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {magazinesData?.length > 0 &&
                      magazinesData.map((item, index) => {
                        return (
                          <tr
                            ref={(e) => (divref.current[index] = e)}
                            key={index}
                          >
                            <td>{item.name}</td>
                            <td>
                              {item.magazine_type.magazine_members.length}
                            </td>
                            <td>{item.magazine_placements.length}</td>
                            <td>{item.magazine_pages.length}</td>
                            <td className={classes.BannerNumber}>
                              {item.magazine_type.magazine_ad_kinds.map(
                                (loc) => {
                                  return (
                                    <span key={loc.id}>
                                      {loc.name}
                                      <br />
                                    </span>
                                  );
                                }
                              )}
                            </td>
                            <td>{item.magazine_type.name}</td>

                            <td className={classes.BannerEditor}>
                              <img
                                src={userEditorIcon}
                                alt="Magazine Actions"
                                onClick={() => {
                                  if (magazinesData.length - 1 > index) {
                                    handleScroll(index);
                                  }
                                  if (ind !== index) {
                                    setShow(!show);
                                    setInd(index);
                                  } else {
                                    setInd("");
                                  }
                                }}
                              />
                              {show && ind === index && (
                                <div
                                  className={classes.EditorWrap}
                                  style={
                                    magazinesData.length - 2 > index
                                      ? {}
                                      : { top: "-70px" }
                                  }
                                  onClick={() => setShow(!show)}
                                  ref={listRef}
                                >
                                  <ul>
                                    <li
                                      onClick={() =>
                                        onCLickViewMagazine(item.id)
                                      }
                                    >
                                      {t("view_magazine")}
                                    </li>
                                    <li
                                      onClick={() =>
                                        onCLickDeleteMagazine(item.id)
                                      }
                                    >
                                      {t("delete_magazine")}
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </MainLayout>
      <ModalMain
        showmodel={showInfoScreen}
        handleClose={() => setShowInfoScreen(false)}
        dialogClassName={classes.ModaleSize}
        title={showTitle}
        children={showContent}
      ></ModalMain>
    </React.Fragment>
  );
};

export default MagazineManagementMain;
