import React, { useState } from "react";
import classes from "./magazinememberform.module.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionAddMembersApiCall } from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";

const MagazineMemberForm = ({ editData, handleClose }) => {
  const agencies = useSelector((state) => state.CommonReducer.orgListData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialFormData = {};
  const [formData, setFormData] = useState(initialFormData);
  const [newMembers, setNewMembers] = useState([]);
  useEffect(() => {}, []);

  const handleAddMembers = () => {
    let closePopup = handleClose;

    const data = {
      new_participants: newMembers,
    };
    dispatch(
      actionAddMembersApiCall({
        data,
        closePopup,
        navigate,
        getapiCall: { data: { id: editData.id }, navigate },
      })
    );
  };
  return (
    <React.Fragment>
      <div className={classes.priceTableFormSection}>
        Add one or more participants
        <div></div>
        <div className={classes.btnwrapper}>
          <>
            <button className={classes.btncancel} onClick={handleClose}>
              Cancel
            </button>
            <button className={classes.btnadd} onClick={handleAddMembers}>
              Add
            </button>
          </>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MagazineMemberForm;
