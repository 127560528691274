import React, { useState } from 'react';
import classes from './brokerPart.module.scss';
import { actionBrokerButtonAddApiCall } from '../../../Redux/Actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const BrokerPartForm = ({ handleClose, editData }) => {
  console.log("the editData is",editData);

  const [formData, setFormData] = useState({
    buttonName: editData?.name || '',
    buttonImage: null,
    isActive: editData?.isActive ?? true,
    buttonLink: editData?.link || '',
    menuType: editData?.menuType || 'Top_menu',
    // New fields for main menu
    sliderImage: null,
    header: editData?.header || '',
    text: editData?.text || '',
    ctaButtonText: editData?.ctaButtonText || '',
    ctaButtonLink: editData?.ctaButtonLink || ''
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [previewUrl, setPreviewUrl] = useState({
    buttonImage: editData?.icon || '',
    sliderImage: editData?.sliderImage || ''
  });
  console.log("the editData is",editData);
  console.log("the previewUrl is",previewUrl);
  

  const [errors, setErrors] = useState({
    buttonName: '',
    buttonImage: '',
    buttonLink: '',
    sliderImage: '',
    header: '',
    text: '',
    ctaButtonText: '',
    ctaButtonLink: ''
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      buttonName: '',
      buttonImage: '',
      buttonLink: '',
      sliderImage: '',
      header: '',
      text: '',
      ctaButtonText: '',
      ctaButtonLink: ''
    };

    // Common validations
    if (formData.buttonName.trim() === '') {
      newErrors.buttonName = 'Button name is required';
      valid = false;
    }

    if (!formData.buttonImage && !previewUrl.buttonImage) {
      newErrors.buttonImage = 'Button image is required';
      valid = false;
    }
    console.log("formData.buttonImage",formData.buttonImage,"editData?.buttonImage",editData?.buttonImage)

    if (formData.buttonLink.trim() === '') {
      newErrors.buttonLink = 'Button link is required';
      valid = false;
    }

    // Additional validations for main menu
    if (formData.menuType === 'Main_menu') {
      if (!formData.sliderImage && !editData?.sliderImage) {
        newErrors.sliderImage = 'Slider image is required for main menu';
        valid = false;
      }

      if (formData.header.trim() === '') {
        newErrors.header = 'Header is required for main menu';
        valid = false;
      }

      if (formData.text.trim() === '') {
        newErrors.text = 'Text content is required for main menu';
        valid = false;
      }

      if (formData.ctaButtonText.trim() === '') {
        newErrors.ctaButtonText = 'CTA button text is required for main menu';
        valid = false;
      }

      if (formData.ctaButtonLink.trim() === '') {
        newErrors.ctaButtonLink = 'CTA button link is required for main menu';
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };

  const handleFileUpload = (file, fieldName) => {
    if (file) {
      if (!file.type.startsWith('image/')) {
        setErrors(prev => ({
          ...prev,
          [fieldName]: 'Please upload an image file'
        }));
        return false;
      }

      if (file.size > 5 * 1024 * 1024) {
        setErrors(prev => ({
          ...prev,
          [fieldName]: 'Image size should be less than 5MB'
        }));
        return false;
      }

      const url = URL.createObjectURL(file);
      setPreviewUrl(prev => ({
        ...prev,
        [fieldName]: url
      }));

      setFormData(prev => ({
        ...prev,
        [fieldName]: file
      }));

      setErrors(prev => ({
        ...prev,
        [fieldName]: ''
      }));

      return true;
    }
    return false;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    if (type === 'file') {
      handleFileUpload(files[0], name);
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const data = {
        name: formData.buttonName,
        file: formData.buttonImage,
        isActive: formData.isActive,
        link: formData.buttonLink,
        menuType: formData.menuType === "Top_menu" ? "Top_menu" : "Main_menu"
      };

      if (formData.menuType === 'Main_menu') {
        Object.assign(data, {
          sliderImage: formData.sliderImage,
          header: formData.header,
          text: formData.text,
          ctaButtonText: formData.ctaButtonText,
          ctaButtonLink: formData.ctaButtonLink
        });
      }
      dispatch(actionBrokerButtonAddApiCall({
        data,
        closePopup: handleClose,
        navigate,
        edit: editData ? editData.id : false,
      }))
    }
  };

  return (
    <div className={classes.menuButtonFormSection}>
      <h3>{editData ? 'Edit Menu Button' : 'Add Menu Button'}</h3>

      <div className={classes.formGroup}>
        <label htmlFor="menuType">Menu Type</label>
        <select
          id="menuType"
          name="menuType"
          value={formData.menuType}
          onChange={handleInputChange}
        >
          <option value="Top_menu">Top Menu</option>
          <option value="Main_menu">Main Menu</option>
        </select>
      </div>

      <div className={classes.formGroup}>
        <label htmlFor="buttonName">Button Name</label>
        <input
          type="text"
          id="buttonName"
          name="buttonName"
          value={formData.buttonName}
          onChange={handleInputChange}
          placeholder="Enter button name"
        />
        {errors.buttonName && <p className={classes.error}>{errors.buttonName}</p>}
      </div>

      <div className={classes.formGroup}>
        <label htmlFor="buttonImage">Button Icon</label>
        <div className={classes.imageUpload}>
          <input
            type="file"
            id="buttonImage"
            name="buttonImage"
            onChange={handleInputChange}
            accept="image/*"
          />
          {previewUrl.buttonImage && (
            <div className={classes.imagePreview}>
              <img src={previewUrl.buttonImage} alt="Button Preview" />
            </div>
          )}
        </div>
        {errors.buttonImage && <p className={classes.error}>{errors.buttonImage}</p>}
      </div>

      <div className={classes.checkboxGroup}>
        <input
          type="checkbox"
          id="isActive"
          name="isActive"
          checked={formData.isActive}
          onChange={handleInputChange}
        />
        <label htmlFor="isActive">Status</label>
        <span className={classes.statusText}>
          {formData.isActive ? 'Active' : 'Inactive'}
        </span>
      </div>

      <div className={classes.formGroup}>
        <label htmlFor="buttonLink">Button Link</label>
        <input
          type="text"
          id="buttonLink"
          name="buttonLink"
          value={formData.buttonLink}
          onChange={handleInputChange}
          placeholder="Enter button link"
        />
        {errors.buttonLink && <p className={classes.error}>{errors.buttonLink}</p>}
      </div>
      {formData.menuType === 'Main_menu' && (
        <>
          <div className={classes.formGroup}>
            <label htmlFor="sliderImage">Slider Image</label>
            <p>Size: 1440px * 500px</p>
            <div className={classes.imageUpload}>
              <input
                type="file"
                id="sliderImage"
                name="sliderImage"
                onChange={handleInputChange}
                accept="image/*"
              />
              {previewUrl.sliderImage && (
                <div className={classes.imagePreview}>
                  <img src={previewUrl.sliderImage} alt="Slider Preview" />
                </div>
              )}
            </div>
            {errors.sliderImage && <p className={classes.error}>{errors.sliderImage}</p>}
          </div>

          <div className={classes.formGroup}>
            <label htmlFor="header">Header</label>
            <input
              type="text"
              id="header"
              name="header"
              value={formData.header}
              onChange={handleInputChange}
              placeholder="Enter header text"
            />
            {errors.header && <p className={classes.error}>{errors.header}</p>}
          </div>

          <div className={classes.formGroup}>
            <label htmlFor="text">Description</label>
            <textarea
              id="text"
              name="text"
              value={formData.text}
              onChange={handleInputChange}
              placeholder="Enter main text content"
              rows="4"
            />
            {errors.text && <p className={classes.error}>{errors.text}</p>}
          </div>

          <div className={classes.formGroup}>
            <label htmlFor="ctaButtonText">CTA Button Text</label>
            <input
              type="text"
              id="ctaButtonText"
              name="ctaButtonText"
              value={formData.ctaButtonText}
              onChange={handleInputChange}
              placeholder="Enter CTA button text"
            />
            {errors.ctaButtonText && <p className={classes.error}>{errors.ctaButtonText}</p>}
          </div>

          <div className={classes.formGroup}>
            <label htmlFor="ctaButtonLink">CTA Button Link</label>
            <input
              type="text"
              id="ctaButtonLink"
              name="ctaButtonLink"
              value={formData.ctaButtonLink}
              onChange={handleInputChange}
              placeholder="Enter CTA button link"
            />
            {errors.ctaButtonLink && <p className={classes.error}>{errors.ctaButtonLink}</p>}
          </div>
        </>
      )}

      <div className={classes.btnWrapper}>
        <button onClick={handleClose} className={classes.btnCancel}>
          Cancel
        </button>
        <button onClick={handleSubmit} className={classes.btnAdd}>
          {editData ? 'Update' : 'Add'} Button
        </button>
      </div>
    </div>
  );
};

export default BrokerPartForm;