import React, { useState } from "react";
import classes from "./singleorgheader.module.scss";
import { useTranslation } from "react-i18next";
import { GiHamburgerMenu } from "react-icons/gi";
import OffcanvasMain from "../Offcanvas";
import SideBar from "../SideBar";
import BlockIcon from "../../../assets/images/BlockIcon.svg";
import DelIcon from "../../../assets/images/DeleteIcon.svg";
import UserIcon from "../../../assets/images/UserIcon.svg";
import ImportIcon from "../../../assets/icons/ImportIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionBlockOrganizationApiCall,
  actionDeleteOrganizationApiCall,
  actionUnblockOrgApiCall,
  actionRunImportApiCall,
} from "../../../Redux/Actions";
import ModalMain from "../Modal";
import AddUserToOrg from "../../ModalComponents/OrganisationManagementModals/AddUserToOrg";
import onlineIcon from "../../../assets/icons/onlineIcon.svg";
import ConfirmationModal from "../ConfirmationModal";
import { useEffect } from "react";

const SingleOrgHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebar, setSideBar] = useState(false);
  const [createuser, setCreateUser] = useState(false);
  const [deleteuser, setdeleteUser] = useState(false);
  const [blockuser, setblockUser] = useState(false);
  const [unBlockuser, setUnblockUser] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const singleOrgData = useSelector(
    (state) => state.CommonReducer.singleOrgData
  );
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(window.innerWidth);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [window]);
  const sidebarShow = () => {
    setSideBar(true);
  };
  const handleClose = () => {
    setSideBar(false);
    setCreateUser(false);
  };
  const onCLickDeleteOrg = () => {
    const data = {
      id: singleOrgData?.id,
    };
    const body = { data, navigate, singledata: true };
    dispatch(actionDeleteOrganizationApiCall(body));
  };

  const onCLickBlockOrg = () => {
    const data = {
      id: singleOrgData?.id,
    };
    const body = { data, navigate, singledata: true };
    dispatch(actionBlockOrganizationApiCall(body));
  };

  const onClickRunImport = () => {
    const data = {
      id: singleOrgData?.id,
    };
    const body = { data, navigate, singledata: true };
    dispatch(actionRunImportApiCall(body));
  };

  const onCLickUnBlockOrg = (id) => {
    const data = {
      id: singleOrgData?.id,
    };
    const body = { data, navigate, singledata: true };
    dispatch(actionUnblockOrgApiCall(body));
  };
  return (
    <React.Fragment>
      <OffcanvasMain showcanvas={sidebar} handleClose={handleClose}>
        <SideBar />
      </OffcanvasMain>
      <div className={classes.containerHeader}>
        <div className={classes.CanvasWarp}>
          <button onClick={sidebarShow}>
            <GiHamburgerMenu />
          </button>
        </div>
        <div className={classes.BreadCrumbLayout}>
          <p
            className={classes.semiBoldLightText}
            onClick={() => navigate("/organisation-management")}
          >
            {t("organisation_management")}
          </p>
          <p className={classes.hyphen}>-</p>
          <p className={classes.semiBoldMedText}>{t("view_organisation")}</p>
        </div>
        <div className={classes.ActionOnUser}>
          <div className={classes.buttonLayout}>
            <button className={classes.addUserbtn} onClick={onClickRunImport}>
              {t("run_import")}
              <img src={ImportIcon} alt="" />
            </button>
            <button
              className={classes.addUserbtn}
              onClick={() => setCreateUser(true)}
            >
              {t("add_user")}
              <img src={UserIcon} alt="" />
            </button>
            <button
              className={classes.addUserbtn}
              onClick={() => setdeleteUser(true)}
            >
              {t("delete_org_header")}
              <img src={DelIcon} alt="" />
            </button>
            {singleOrgData?.status && (
              <>
                {singleOrgData?.status == "online" ? (
                  <button
                    className={classes.addUserbtn}
                    onClick={() => setblockUser(true)}
                  >
                    {t("block_org_header")}
                    <img src={BlockIcon} alt="" />
                  </button>
                ) : (
                  <button
                    className={classes.addUserbtn}
                    onClick={() => setUnblockUser(true)}
                  >
                    {t("online_org1")}
                    <img src={onlineIcon} alt="" />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ModalMain
        showmodel={createuser}
        handleClose={() => setCreateUser(false)}
        size={"xl"}
      >
        <AddUserToOrg handleClose={handleClose} />
      </ModalMain>
      <ConfirmationModal
        showmodel={unBlockuser}
        handleClose={() => setUnblockUser(false)}
        onClickYesButton={onCLickUnBlockOrg}
        title={`You are about to online ${singleOrgData?.name} Organisation`}
        size={"md"}
        newDesign={true}
        btnName={"Online"}
        MainTitle={"Online Org"}
      ></ConfirmationModal>
      <ConfirmationModal
        showmodel={deleteuser}
        handleClose={() => setdeleteUser(false)}
        onClickYesButton={onCLickDeleteOrg}
        title={`You are about to delete ${singleOrgData?.name} Organisation`}
        size={"md"}
        newDesign={true}
        btnName={"Delete"}
        MainTitle={"Delete Org"}
      ></ConfirmationModal>
      <ConfirmationModal
        showmodel={blockuser}
        handleClose={() => setblockUser(false)}
        onClickYesButton={onCLickBlockOrg}
        title={`You are about to block ${singleOrgData?.name} Organisation`}
        size={"md"}
        newDesign={true}
        btnName={"Block"}
        MainTitle={"Block Org"}
      ></ConfirmationModal>
    </React.Fragment>
  );
};

export default SingleOrgHeader;
