import React, { useEffect } from "react";
import classes from "./magazinedetailsmain.module.scss";
import MainLayount from "../common/MainLayout";
import MagazineDetailsHeader from "../common/MagazineDetailsHeader";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  actionUpdateMagazineApiCall,
  actionViewMagazineApiCall,
  actionGetMagazineMetaDataApiCall,
} from "../../Redux/Actions";
import EditIcon from "../../assets/images/EditLogo.svg";
import DeleteIcon from "../../assets/images/DeleteIconGray.svg";
import SearchIcon from "../../assets/images/SearchIcon.svg";
import StarIcon from "../../assets/images/starIcon.svg";
import { FaUserPlus } from "react-icons/fa";
import { RiGlobalLine } from "react-icons/ri";
import OffcanvasMain from "../common/Offcanvas";
import LocationForm from "../common/LocationForm";
import MagazineMemberForm from "../common/MagazineMemberForm";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const MagazineDetailsMain = () => {
  const { t } = useTranslation();

  const magazineData = useSelector(
    (state) => state.CommonReducer.saveMagazineData
  );
  const magazineTypes = useSelector(
    (state) => state.CommonReducer.magazineTypesData
  );

  console.log("magazineTypes", magazineTypes);
  const [magazineName, setMagazineName] = useState(`${magazineData?.name}`);
  const [magazineType, setMagazineType] = useState(
    magazineData?.magazine_type.name
  );
  const [magazinePubDate, setMagazinePubDate] = useState(
    magazineData?.publication_date
  );
  const [magazineProdDate, setMagazineProdDate] = useState(
    magazineData?.production_date
  );
  const [magazineDeadline, setMagazineDeadline] = useState(
    magazineData?.deadline
  );
  const [participants, setParticipants] = useState(
    magazineData?.magazine_type.magazine_members
  );

  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [locationFormShow, setLocationFormShow] = useState({
    data: null,
    status: false,
  });
  const [mmFormShow, setMmFormShow] = useState({
    data: null,
    status: false,
  });

  const dispatch = useDispatch();
  const pathname = useLocation().pathname;

  useEffect(() => {
    console.log("magazineData", magazineData);
    if (!magazineData) {
      console.log(pathname);
      const id = pathname.substring(pathname.lastIndexOf("/") + 1);
      console.log("data", id);
      const data = { id: id };
      const body = { data, navigate };
      dispatch(actionViewMagazineApiCall(body));
      dispatch(actionGetMagazineMetaDataApiCall({ navigate }));
    }
    setMagazineName(magazineData?.name);
    setMagazineType(magazineData?.magazine_type_id);
    setMagazinePubDate(magazineData?.publication_date);
    setMagazineProdDate(magazineData?.production_date);
    setMagazineDeadline(magazineData?.deadline);
  }, [magazineData, pathname, navigate, dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
  };

  const onClickOfSaveBtn = () => {
    const id = magazineData.id;
    const data = {
      magazine_id: magazineData.id,
      magazine_type_id: magazineType,
      magazine_name: magazineName,
      publication_date: magazinePubDate,
      production_date: magazineProdDate,
      deadline: magazineDeadline,
      members: magazineData.magazine_type.magazine_members,
    };
    console.log("onClickOfSaveBtn", data);

    dispatch(
      actionUpdateMagazineApiCall({
        data,
        edit: id,
        navigate,
        getapiCall: { data: { id: id }, navigate },
      })
    );
  };

  const handleChange = (event) => {
    const { name, value } = event?.target;
    if (name === "magazine_name") setMagazineName(value);
    if (name === "magazine_type") setMagazineType(value);
    if (name === "publication_date") setMagazinePubDate(value);
    if (name === "production_date") setMagazineProdDate(value);
    if (name === "deadline") setMagazineDeadline(value);
  };

  const removeMember = (id) => {
    const tmpParticipants = participants.filter((el) => el.id !== id);
    setParticipants(tmpParticipants);
  };

  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.main}>
          <MagazineDetailsHeader />
          <div className={classes.detailPage}>
            <div className={classes.gridWrapper}>
              <div className={classes.gridContainer}>
                <div key="magazine_name" className={classes.labelContainer}>
                  <label>Name</label>
                  <div className={classes.inputContainer}>
                    <input
                      type="text"
                      name="magazine_name"
                      defaultValue={magazineData?.name}
                      onChange={(event) => handleChange(event)}
                    />
                  </div>
                  <p className={classes.errorText}>{error["magazine_name"]}</p>
                </div>
                <div className={classes.labelContainer}>
                  <label>Type</label>
                  <div className={classes.inputContainer}>
                    <select
                      name="magazine_type"
                      value={magazineType}
                      onChange={(event) => handleChange(event)}
                    >
                      <option value="">Select an option</option>
                      {magazineTypes &&
                        magazineTypes?.map((option, index) => (
                          <option key={option.id} value={option.id}>
                            {option.name} ({option.script_class})
                          </option>
                        ))}
                    </select>
                  </div>
                  <p className={classes.errorText}>{error["magazine_type"]}</p>
                </div>
                <div className={classes.labelContainer}>
                  <label>Publication date</label>
                  <div className={classes.inputContainer}>
                    <input
                      type="date"
                      name="publication_date"
                      defaultValue={magazineData?.publication_date}
                      onChange={(event) => handleChange(event)}
                    />
                  </div>
                  <p className={classes.errorText}>
                    {error["magazine_publication_date"]}
                  </p>
                </div>
                <div className={classes.labelContainer}>
                  <label>Deadline</label>
                  <div className={classes.inputContainer}>
                    <input
                      type="datetime-local"
                      name="deadline"
                      defaultValue={magazineData?.deadline}
                      onChange={(event) => handleChange(event)}
                    />
                  </div>
                  <p className={classes.errorText}>
                    {error["magazine_deadline"]}
                  </p>
                </div>
                <div className={classes.labelContainer}>
                  <label>Production date</label>
                  <div className={classes.inputContainer}>
                    <input
                      type="date"
                      name="production_date"
                      defaultValue={magazineData?.production_date}
                      onChange={(event) => handleChange(event)}
                    />
                  </div>
                  <p className={classes.errorText}>
                    {error["magazine_production_date"]}
                  </p>
                </div>
              </div>
              <button onClick={onClickOfSaveBtn}>Save</button>
            </div>
            <div className={classes.container}>
              <div className={classes.address}>
                <p>{t("magazine_ad_kinds")}</p>
                <div className={classes.addressContainer}>
                  <div className={classes.tableContainer}>
                    <div className={classes.SearchBar}>
                      <img src={SearchIcon} alt="" />
                      <input
                        type="search"
                        placeholder="Search in Ad kinds"
                        // onChange={(e) => setAddressSearch(e.target.value)}
                      />
                    </div>
                    <div className={classes.TableListngLayOut}>
                      <table>
                        <tr>
                          <th></th>
                          <th>{t("id")}</th>
                          <th>{t("magazine_ad_kind_name")}</th>
                          <th>{t("magazine_ad_kind_sort")}</th>
                          <th></th>
                        </tr>
                        {magazineData?.magazine_type?.magazine_ad_kinds
                          ?.length > 0 ? (
                          magazineData?.magazine_type?.magazine_ad_kinds?.map(
                            (item) => (
                              <tr
                                className={
                                  item?.hide_property == 1
                                    ? classes.InvisibleCard
                                    : null
                                }
                              >
                                <td></td>
                                <td>{item?.id}</td>
                                <td>{item?.name}</td>
                                <td>{item?.sort}</td>
                                <td
                                  onClick={() =>
                                    setLocationFormShow({
                                      data: item,
                                      status: true,
                                    })
                                  }
                                >
                                  <div className={classes.ListingIcons}>
                                    <img src={EditIcon} alt="" />
                                  </div>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr className={classes.NoData}>
                            <td className={classes.LoaderWrap} colSpan={6}>
                              <h2>{t("no_data_found")}</h2>
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                  <button
                    onClick={() =>
                      setLocationFormShow({
                        data: null,
                        status: true,
                      })
                    }
                  >
                    add ad kind
                  </button>
                </div>
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.address}>
                <div className={classes.contact}>
                  <p>{t("magazine_members")}</p>
                </div>
                <div className={classes.addressContainer}>
                  <div className={classes.tableContainer}>
                    <div className={classes.SearchBar}>
                      <img src={SearchIcon} alt="" />
                      <input
                        type="search"
                        placeholder="Search in Participants"
                        // onChange={(e) => setContactSearch(e.target.value)}
                      />
                    </div>
                    <div
                      className={`${classes.TableListngLayOut} ${classes.contactTable}`}
                    >
                      <table>
                        <tr>
                          <th></th>
                          <th>{t("NAME")}</th>
                          <th>{t("ADDRESS")}</th>
                          <th>{t("CITY")}</th>
                          <th>{t("EMAIL")}</th>
                          <th></th>
                          <th></th>
                        </tr>
                        {participants?.length > 0 ? (
                          participants?.map((item) => (
                            <tr
                              className={
                                item?.hide_property == 1
                                  ? classes.InvisibleCard
                                  : null
                              }
                            >
                              <td
                                className={`${classes.starIcon} ${classes.iconStyle}`}
                              >
                                {item?.can_create && (
                                  <img src={StarIcon} alt="" />
                                )}
                              </td>
                              <td className={classes.setContent}>
                                {item?.member_agency.name}
                              </td>
                              <td>{item?.member_agency.address}</td>
                              <td>{item?.member_agency.city}</td>
                              <td>{item?.member_agency.email}</td>
                              <td></td>
                              <td onClick={() => removeMember(item.id)}>
                                <div className={classes.ListingIcons}>
                                  <img src={DeleteIcon} alt="" />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className={classes.NoData}>
                            <td className={classes.LoaderWrap} colSpan={10}>
                              <h2>{t("no_data_found")}</h2>
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>

                  <button
                    onClick={() =>
                      setMmFormShow({ data: participants, status: true })
                    }
                  >
                    add participant
                  </button>
                </div>
              </div>
            </div>
          </div>

          <OffcanvasMain
            showcanvas={locationFormShow.status}
            placement={"end"}
            handleClose={() =>
              setLocationFormShow({ ...locationFormShow, status: false })
            }
            backdropClassName="abc"
            backgroundColor="true"
          >
            <LocationForm
              handleClose={() =>
                setLocationFormShow({ ...locationFormShow, status: false })
              }
              editData={locationFormShow.data}
            />
          </OffcanvasMain>
          <OffcanvasMain
            showcanvas={mmFormShow.status}
            placement={"end"}
            handleClose={() => setMmFormShow({ ...mmFormShow, status: false })}
            backdropClassName="abc"
            backgroundColor="true"
          >
            <MagazineMemberForm
              handleClose={() =>
                setMmFormShow({ ...mmFormShow, status: false })
              }
              editData={mmFormShow.data}
            />
          </OffcanvasMain>
        </div>
      </MainLayount>
    </React.Fragment>
  );
};

export default MagazineDetailsMain;
