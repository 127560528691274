import React, { useEffect, useRef, useState } from "react";
import classes from "./neighbourhoodform.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { actionAddNeighbourhoodDataApiCall } from "../../../Redux/Actions";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";
import upload from "../../../assets/icons/FileUploadIcon.svg";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../CustomSelect";
import ReactQuill, { Quill } from "react-quill";
import Swal from "sweetalert2";
import { isValidUrl, rgbStringToHex } from "../../../utils/commonFunctions";
import APIUrl from "../../../API/APIUrl";
class ImageLinkBlot extends Quill.import("formats/link") {
  static create(linkData) {
    const node = super.create(linkData);
    node.setAttribute("href", linkData);
    node.setAttribute("target", "_blank"); // Open the link in a new tab
    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute("href");
  }

  format(name, value) {
    if (name === "imageLink" && value) {
      this.domNode.setAttribute("href", this.constructor.sanitize(value));
      this.domNode.setAttribute("target", "_blank");
    } else {
      super.format(name, value);
    }
  }
}

ImageLinkBlot.blotName = "imageLink";
ImageLinkBlot.tagName = "a";
Quill.register(ImageLinkBlot, true);

const EditableButtonLink = Quill.import('blots/block/embed');

class ButtonBlot extends EditableButtonLink {
  static create(value) {
    let node = super.create();
    const innerHTML = typeof value === 'string' ? value.trim() : value.innerHTML;
    const buttonText = typeof value === 'string' ? value.trim() : value.buttonText.trim();
    const color = typeof value === 'string' ? 'rgb(255, 255, 255)' : value.color;
    const background = typeof value === 'string' ? 'rgb(0, 0, 0)' : value.background;
    const href = typeof value === 'string' ? window.location.origin : value.href;

    if (innerHTML.includes('<img')) {
      node.setAttribute('href', href);
      return node;
    }

    if (typeof value !== 'string' && value.contenteditable === null) {
      node.setAttribute('href', href);
      return node;
    }

    const aTag = document.createElement('a');
    aTag.setAttribute('href', href);
    aTag.setAttribute('target', '_blank');
    aTag.setAttribute('style', `
     background: ${background};
    color: ${color};
    font-size: 18px;
    
  padding: 10px 20px;
    width:100%;    
    border: none;
    border-radius: 3px;
    text-align: center;
    text-decoration: none;
    display: inline-block
    `);
    aTag.textContent = buttonText;

    node.appendChild(aTag);

    node.setAttribute('contenteditable', false);
    node.setAttribute('style', `
    background: ${background};
    color: ${color};
    font-size: 18px;
    padding: 0;
    border: none;
    display: flex;
     
    border-radius: 3px;
    text-align: center;
    margin: 5px auto;
 
    `);

    node.onclick = (event) => {
      event.preventDefault();

      Swal.fire({
        title: 'Edit Button Properties',
        html: `
    <style>
      #swal-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .swal-form-group {
          display: flex;
          flex-direction: column;        
          column-gap:15px;        
      }
      .swal-form-group label {        
          text-wrap:nowrap;
      }
      .swal-form-group input {
        flex-grow: 1;
        margin:0;
        padding:0;
        padding :0 6px;
      }      
      .swal-form-group input[type="color"]{      
        cursor:pointer;
        padding: 5px;
      }
    </style>
    <form id="swal-form">
      <div class="swal-form-group">
        <label for="buttonText">Button Name:</label>
        <input type="text" id="buttonText" class="swal2-input" value="${aTag.textContent.trim()}">
      </div>
      <div class="swal-form-group">
        <label for="linkUrl">URL:</label>
        <input type="text" id="linkUrl"  class="swal2-input" value="${aTag.href}">
      </div>
      <div class="swal-form-group">
        <label for="textColor">Text Color:</label>
        <input type="color" id="textColor" class="swal2-input" value="${rgbStringToHex(aTag.style.color)}">
      </div>
      <div class="swal-form-group">
        <label for="bgColor">Background Color:</label>
        <input type="color" id="bgColor" class="swal2-input" value="${rgbStringToHex(aTag.style.background)}">
      </div>
    </form>
  `,
        showCancelButton: true,
        preConfirm: () => {
          const form = document.getElementById('swal-form');
          const buttonText = form.buttonText.value.trim();
          const linkUrl = form.linkUrl.value.trim();
          const textColor = form.textColor.value;
          const bgColor = form.bgColor.value;

          if (!buttonText?.trim()) {
            Swal.showValidationMessage('Please enter a Button text');
            return false;
          }

          if (!isValidUrl(linkUrl)) {
            Swal.showValidationMessage('Please enter a valid URL');
            return false;
          }

          return {
            buttonText,
            linkUrl,
            textColor,
            bgColor
          };
        },
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          const { buttonText, linkUrl, textColor, bgColor } = result.value;

          aTag.textContent = buttonText;
          aTag.href = linkUrl;
          aTag.style.color = textColor;
          aTag.style.background = bgColor;
        }
      });

    };

    return node;
  }

  static value(node) {
    const aTag = node.querySelector('a');
    return {
      href: aTag.getAttribute('href'),
      innerHTML: aTag.innerHTML,
      buttonText: aTag.textContent.trim(),
      background: aTag.style.background,
      color: aTag.style.color,
      contenteditable: node.getAttribute('contenteditable')
    };
  }
}

ButtonBlot.blotName = 'custom_Button_Link';
ButtonBlot.tagName = 'button';

Quill.register(ButtonBlot, true);
const NeighbourhoodForm = ({ editData, handleClose }) => {
  const url = `${APIUrl.backendUrl}/add-seo-image`
  const [count, setCount] = useState(null)
  const [submitAllowed, setSubmitAllowed] = useState(true)
  const { t } = useTranslation();
  const loader = useSelector((state) => state.CommonReducer.loader);
  const buttonloader = useSelector((state) => state.CommonReducer.buttonloader);
  const state = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    description: "",
    image: "",
    city_id: "",
    subheader: "",
    header: "",
    shorttextfield: "",
    button1_text: "",
    button1_link: "",
    button2_text: "",
    button2_link: "",
    seo_text: "",
    htmlContent: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    code: "",
    image: "",
    city_id: "",
    subheader: "",
    header: "",
    shorttextfield: "",
    button1_text: "",
    button1_link: "",
    button2_text: "",
    button2_link: "",
  });
  useEffect(() => {
    if (count === 0) {
      console.log(count, "count count");
      setSubmitAllowed(true);
      setCount(null)
    }
  }, [count])
  const SelectItems = {
    name: "city_id",
    apiName: "city",
    placeholder: t("city_p"),
    options:
      state?.getCityData &&
      state?.getCityData?.map((p) => {
        return { label: p.name, value: p.id };
      }),
  };
  useEffect(() => {
    if (editData) {
      setFormData({
        name: editData?.name || "",
        code: editData?.code || "",
        description: editData?.description || "",
        image: editData?.slider_image || "",
        city_id: editData.city_id || "",
        subheader: editData?.sub_header || "",
        header: editData?.header || "",
        shorttextfield: editData?.short_text || "",
        button1_text: editData?.button1_text || "",
        button1_link: editData?.button1_link || "",
        button2_text: editData?.button2_text || "",
        button2_link: editData?.button2_link || "",
        seo_text: editData?.seo_text || "",
        htmlContent: editData?.html_editor_content || "",
      });
    }
  }, [editData]);

  const isValidUrl2 = (urlString) => {
    const urlPattern = /^(https?|ftp):\/\/[^\s$.?#-][^\s]*([\/?][^\s]*)?$/;
    return !!urlPattern.test(urlString);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: "",
      code: "",
      image: "",
      city_id: "",
      subheader: "",
      header: "",
      shorttextfield: "",
      button1_text: "",
      button1_link: "",
      button2_text: "",
      button2_link: "",
    };

    if (formData.city_id === "") {
      newErrors.city_id = t("city_p") + " " + t("required");
      valid = false;
    }
    if (formData.name.trim() === "") {
      newErrors.name = t("name_required");
      valid = false;
    }
    if (formData.code === "") {
      newErrors.code = t("code") + " " + t("required");
      valid = false;
    }
    if (formData.image === "") {
      newErrors.image = t("image_required");
      valid = false;
    }
    if (formData.subheader === "") {
      newErrors.subheader = t("Subheader required");
      valid = false;
    }
    if (formData.header === "") {
      newErrors.header = t("Header required");
      valid = false;
    }
    if (formData.shorttextfield === "") {
      newErrors.shorttextfield = t("Shorttext Field required");
      valid = false;
    }
    if (formData.button1_text === "") {
      newErrors.button1_text = t("button1 Text required");
      valid = false;
    }
    if (formData.button1_link === "" || !isValidUrl2(formData.button1_link)) {
      newErrors.button1_link = t("button1 Link required");
      valid = false;
    }
    if (formData.button2_text === "") {
      newErrors.button2_text = t("button2 Text required");
      valid = false;
    }
    if (formData.button2_link === "" || !isValidUrl2(formData.button2_link)) {
      newErrors.button2_link = t("button2 Link required");
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    const quill = quillRef.current.getEditor();
    let clickTimer = null;

    const handleImageUpload = () => {
      console.log("Image button clicked");
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/*";
      fileInput.click();

      fileInput.onchange = async () => {
        const file = fileInput.files[0];
        if (file) {
          const range = quill.getSelection(true);
          const loadingText = "Loading...";
          quill.insertText(range.index, loadingText, Quill.sources.USER);
          quill.setSelection(range.index + loadingText.length);

          // Attempt to upload the image
          const uploadedImageUrl = await uploadImage(
            file,
            range.index,
            loadingText.length
          );
          if (uploadedImageUrl) {
            console.log("Image uploaded successfully:", uploadedImageUrl);
          } else {
            console.error("Image upload failed");
          }
        } else {
          console.error("No file selected for upload");
        }
      };
    };

    const toolbar = quill.getModule("toolbar");
    toolbar.addHandler("image", handleImageUpload);

    // Add custom handlers for image click and double-click
    const handleImageClick = (event) => {
      const target = event?.target;
      if (target && target.tagName === "IMG") {
        clearTimeout(clickTimer);
        clickTimer = setTimeout(() => {
          const parentAnchor = target?.parentNode;
          if (parentAnchor && (parentAnchor.tagName === "A" || parentAnchor.tagName === "a")) {
            // If the image is already wrapped in a link, prompt to edit the link

            const currentLink = parentAnchor?.getAttribute("href");
            Swal.fire({
              title: 'Edit the link for the image',
              input: 'url',
              inputValue: currentLink,
              showCancelButton: true,
              inputValidator: (value) => {
                if (!isValidUrl(value)) {
                  return 'You need to enter a valid URL!';
                }
                // Optional: Add more validation if needed
                return null;
              }
            }).then((result) => {
              if (result.isConfirmed) {
                const newLinkUrl = result.value;
                parentAnchor?.setAttribute("href", newLinkUrl);
                console.log("Link updated:", newLinkUrl); // Log the updated link
              }
            });
          } else {
            // If the image is not wrapped in a link, prompt the user for a new link
            Swal.fire({
              title: 'Enter a link for the image',
              input: 'url',
              showCancelButton: true,
              inputValidator: (value) => {
                if (!isValidUrl(value)) {
                  return 'You need to enter a valid URL!';
                }
                // Optional: Add more validation if needed
                return null;
              }
            }).then((result) => {
              if (result.isConfirmed) {
                const linkUrl = result.value;
                if (linkUrl) {
                  // Create a new anchor element and wrap the image with it
                  const anchor = document.createElement('a');
                  anchor.setAttribute('href', linkUrl);
                  anchor.setAttribute('target', '_blank');
                  target.parentNode.insertBefore(anchor, target);
                  anchor.appendChild(target);
                  console.log("Link added:", linkUrl); // Log the added link
                }
              }
            });
          }
        }, 300); // Delay for single click
      }
    };

    const handleImageDoubleClick = (event) => {
      clearTimeout(clickTimer);
      const target = event.target;
      if (target && target.tagName === "IMG") {
        const parentAnchor = target.parentNode;
        if (parentAnchor && parentAnchor.tagName === "A") {
          // If the image is wrapped in a link, open the link in a new tab
          event.preventDefault(); // Prevent default action
          const linkUrl = parentAnchor.getAttribute("href");
          if (linkUrl) {
            window.open(linkUrl, "_blank");
          }
        }
      }
    };

    quill.root.addEventListener("click", handleImageClick);
    quill.root.addEventListener("dblclick", handleImageDoubleClick);

    return () => {
      quill.root.removeEventListener("click", handleImageClick);
      quill.root.removeEventListener("dblclick", handleImageDoubleClick);
      // toolbar?.removeHandler("image", handleImageUpload);
    };
  }, []);

  let dummyCount = 0;
  const uploadImage = async (file, index, length) => {
    setSubmitAllowed(false)
    dummyCount = dummyCount + 1;

    const formData = new FormData();
    formData.append("logo", file);

    try {
      console.log("Uploading image...");
      const response = await fetch(url,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        // setSubmitAllowed(true)
        setCount(dummyCount - 1)
        dummyCount = dummyCount - 1;
        const uploadedImageUrl = result.url; // Adjust this based on your API response structure

        // Replace the loading text with the uploaded image URL
        const quill = quillRef.current.getEditor();
        quill.deleteText(index, length, Quill.sources.USER); // Remove loading text
        quill.insertEmbed(index, "image", uploadedImageUrl, Quill.sources.USER);
        quill.setSelection(index + 1);
        logContentAndImages(); // Log content and images when a new image is added
        setSubmitAllowed(true)
        return uploadedImageUrl;
      } else {
        console.error("Error uploading image: ", response.statusText);
        setSubmitAllowed(true)
        return null;
      }
    } catch (error) {
      console.error("Error uploading image: ", error);
      return null;
    }
  };
  const logContentAndImages = () => {
    const quill = quillRef.current.getEditor();
    const contents = quill.getContents();
    const deltaOps = contents.ops;
    const images = deltaOps
      .filter((op) => op.insert && op.insert.image)
      .map((op) => op.insert.image);

    console.log("Editor Content:", quill.root.innerHTML);
    console.log("Images:", images);
  };
  const handleEditorChange = (content, delta, source, editor) => {
    setFormData({ ...formData, htmlContent: content });
    logContentAndImages()
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const data = new FormData();
      data.append("name", formData.name);
      data.append("code", formData.code);
      data.append("description", formData.description);
      data.append("sub_header", formData.subheader);
      data.append("header", formData.header);
      data.append("short_text", formData.shorttextfield);
      data.append("button1_text", formData.button1_text);
      data.append("button1_link", formData.button1_link);
      data.append("button2_text", formData.button2_text);
      data.append("button2_link", formData.button2_link);
      data.append("seo_text", formData.seo_text);
      data.append("html_editor_content", formData.htmlContent);
      data.append(
        "file",
        formData["image 1"] ? formData["image 1"] : formData["image"]
      );
      data.append(
        "city_id",
        formData.city_id && formData.city_id.value
          ? formData.city_id.value
          : formData.city_id
      );

      const closePopup = handleClose;
      dispatch(
        actionAddNeighbourhoodDataApiCall({
          data,
          closePopup,
          edit: editData ? editData.id : false,
          navigate,
        })
      );
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
        "image/svg+xml",
      ];
      if (allowedTypes.includes(file.type)) {
        const maxSize = 2 * 1024 * 1024;
        if (file.size > maxSize) {
          toast.error("Image size must not exceed 2 MB.");
        } else {
          const reader = new FileReader();

          reader.onload = (e) => {
            setFormData({
              ...formData,
              [`${event.target.name} 1`]: file,
              [event.target.name]: e.target.result,
            });
          };
          setErrors({ ...errors, [event.target.name]: "" });
          reader.readAsDataURL(file);
        }
      } else {
        toast.error("Invalid file type. Please select a valid image file.");
      }
    }
  };
  const clearImage = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"], // Include custom_Button_Link in the toolbar
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link", // Add custom_Button_Link to the formats
  ];
  const quillRef = useRef(null);
  return (
    <React.Fragment>
      <div className={classes.branchesFormSection}>
        {editData ? (
          <h3>
            {t("edit")} {t("neighbourhood")}
          </h3>
        ) : (
          <h3>
            {t("add")} {t("neighbourhood")}
          </h3>
        )}
        <div className={classes.inputsWrapper}>
          <div className={`${classes.Text} ${classes.SelectText}`}>
            <label>{SelectItems.placeholder}</label>
            <CustomSelect
              defaultInputValue={editData ? true : false}
              value={
                editData && {
                  label: editData[SelectItems.placeholder.toLowerCase()]?.name,
                  value: editData[SelectItems.placeholder.toLowerCase()]?.id,
                }
              }
              item={SelectItems}
              apiName={SelectItems.apiName}
              isInputChange={true}
              headerSearch={formData}
              setHeaderSearch={setFormData}
              errors={errors}
              setErrors={setErrors}
            />
            {errors[SelectItems.name] && (
              <p className={classes.error}>{errors[SelectItems.name]}</p>
            )}
          </div>
          <div className={classes.Text}>
            <label htmlFor="name">{t("countriename")}</label>
            <input
              type="text"
              name="name"
              onChange={handleInputChange}
              onBlur={validateForm}
              value={formData.name}
            />
            {errors.name && <p className={classes.error}>{errors.name}</p>}
          </div>
          <div className={classes.Text}>
            <label htmlFor="code">{t("code")}</label>
            <input
              type="text"
              name="code"
              onChange={handleInputChange}
              onBlur={validateForm}
              value={formData.code}
            />
            {errors.code && <p className={classes.error}>{errors.code}</p>}
          </div>
          <div className={classes.Text}>
            <label htmlFor="subheader">{t("subheader")}</label>
            <input
              type="text"
              name="subheader"
              onChange={handleInputChange}
              onBlur={validateForm}
              value={formData.subheader}
            />
            {errors.subheader && <p className={classes.error}>{errors.subheader}</p>}
          </div>
          
          {/* New form fields */}
          <div className={classes.Text}>
            <label htmlFor="header">{t("header")}</label>
            <input
              type="text"
              name="header"
              onChange={handleInputChange}
              onBlur={validateForm}
              value={formData.header}
            />
            {errors.header && <p className={classes.error}>{errors.header}</p>}
          </div>
          <div className={classes.Text}>
            <label htmlFor="shorttextfield">{t("Shorttext Field")}</label>
            <textarea
              name="shorttextfield"
              onChange={handleInputChange}
              value={formData.shorttextfield}
              onBlur={validateForm}
              rows="4"
            />
            {errors.shorttextfield && 
              <p className={classes.error}>{errors.shorttextfield}</p>
            }
          </div>
          <div className={classes.Text}>
            <label htmlFor="button1_text">{t("button1 text")}</label>
            <input
              type="text"
              name="button1_text"
              onChange={handleInputChange}
              onBlur={validateForm}
              value={formData.button1_text}
            />
            {errors.button1_text && 
              <p className={classes.error}>{errors.button1_text}</p>
            }
          </div>

          <div className={classes.Text}>
            <label htmlFor="button1_link">{t("button1 link")}</label>
            <input
              type="text"
              name="button1_link"
              onChange={handleInputChange}
              onBlur={validateForm}
              value={formData.button1_link}
            />
            {errors.button1_link && 
              <p className={classes.error}>{errors.button1_link}</p>
            }
          </div>

          <div className={classes.Text}>
            <label htmlFor="button2_text">{t("button2 text")}</label>
            <input
              type="text"
              name="button2_text"
              onChange={handleInputChange}
              onBlur={validateForm}
              value={formData.button2_text}
            />
            {errors.button2_text && 
              <p className={classes.error}>{errors.button2_text}</p>
            }
          </div>

          <div className={classes.Text}>
            <label htmlFor="button2_link">{t("button2 link")}</label>
            <input
              type="text"
              name="button2_link"
              onChange={handleInputChange}
              onBlur={validateForm}
              value={formData.button2_link}
            />
            {errors.button2_link && 
              <p className={classes.error}>{errors.button2_link}</p>
            }
          </div>

          <div className={classes.Text}>
            <label htmlFor="seo_text">{t("SEO text")}</label>
            <textarea
              name="seo_text"
              onChange={handleInputChange}
              value={formData.seo_text}
              onBlur={validateForm}
              rows="4"
            />
          </div>
          <div className={classes.Text}>
            <label htmlFor="name">{t("description")}</label>
            <textarea
              name="description"
              onChange={handleInputChange}
              onBlur={validateForm}
              value={formData.description}
              rows="4"
            />
          </div>
          <div className={classes.Text}>
            <label htmlFor="htmlContent">{t("HTML Editor")}</label>
            <ReactQuill
              ref={quillRef}
              value={formData.htmlContent}
              onChange={handleEditorChange}
              modules={modules}
              formats={formats}
            />
          </div>
          <div className={classes.uploadPicWrapper}>
            <div className={classes.imageprview}>
              <label htmlFor="file-upload1">Slider image</label>
              <div className={`${classes.preview} `}>
                {formData.image && formData.image != "null" ? (
                  <div className={`${classes.innerpreview} `}>
                    <img src={formData.image} alt="Profile Preview" />
                    <div
                      className={classes.cross}
                      onClick={() => clearImage("image")}
                    >
                      <IoClose />
                    </div>
                  </div>
                ) : (
                  <div className={`${classes.innerpreview}`}>
                    <p>
                      Slider <br />
                      image
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className={classes.upload}>
              <div className={classes.uploadImg}>
                <label htmlFor="file-upload1">
                  <img src={upload} alt="Upload" />
                </label>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png,.webp,.svg"
                  id="file-upload1"
                  style={{ display: "none" }}
                  name="image"
                  onChange={handleImageChange}
                />
              </div>

              <div className={classes.labelupload}>
                <label htmlFor="file-upload1">Upload New</label>
              </div>
            </div>
          </div>
          {errors.image && <p className={classes.error}>{errors.image}</p>}
        </div>

        <div className={classes.btnwrapper}>
          <div className={classes.btnWrapperShift}>
            {editData ? (
              <>
                <button className={classes.btncancel} onClick={handleClose}>
                  {t("cancel")}
                </button>
                <button disabled={!submitAllowed || buttonloader} className={classes.btnadd} onClick={handleSubmit}>
                  {state?.buttonloader ? <Loader /> : `${t("edit")}`}
                </button>
              </>
            ) : (
              <>
                <button className={classes.btncancel} onClick={handleClose}>
                  {t("cancel")}
                </button>
                <button disabled={!submitAllowed || buttonloader} className={classes.btnadd} onClick={handleSubmit}>
                  {state?.buttonloader ? (
                    <Loader />
                  ) : (
                    `${t("add")} ${t("neighbourhood")}`
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NeighbourhoodForm;
